import { default as _91_46_46_46slug_93tAZZNJfhC3Meta } from "/app/pages/admin/dynamic/[...slug].vue?macro=true";
import { default as indexn9dl13pW5ZMeta } from "/app/pages/admin/dynamic/index.vue?macro=true";
import { default as newIAQcbbAuOUMeta } from "/app/pages/admin/dynamic/new.vue?macro=true";
import { default as _91name_93DJuuWVgzRpMeta } from "/app/pages/admin/markets/[name].vue?macro=true";
import { default as indexOmfdIb5YwoMeta } from "/app/pages/admin/markets/index.vue?macro=true";
import { default as newRt0nxzI242Meta } from "/app/pages/admin/markets/new.vue?macro=true";
import { default as admin0eiku7Jj1aMeta } from "/app/pages/admin.vue?macro=true";
import { default as _91_46_46_46slug_93ZsrKoCxHTyMeta } from "/app/pages/bargains/[...slug].vue?macro=true";
import { default as indexWiIX7Nv00WMeta } from "/app/pages/blog/[category]/[link]/index.vue?macro=true";
import { default as indexLnW0bqcI2xMeta } from "/app/pages/blog/[category]/index.vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as _91link_93V33zwQ8ZK7Meta } from "/app/pages/blog/moderation/edit/[category]/[link].vue?macro=true";
import { default as indexL1ZOmhUMP0Meta } from "/app/pages/blog/moderation/edit/[category]/index.vue?macro=true";
import { default as index5Gp0FVnsX3Meta } from "/app/pages/blog/moderation/index.vue?macro=true";
import { default as newaLo2T0Xve9Meta } from "/app/pages/blog/moderation/new.vue?macro=true";
import { default as _91id_93mx4r6ubHpBMeta } from "/app/pages/colors/[id].vue?macro=true";
import { default as index6gs5LC672RMeta } from "/app/pages/colors/index.vue?macro=true";
import { default as comparisonOPMyBPcTFrMeta } from "/app/pages/comparison.vue?macro=true";
import { default as indexDMNmqPeymcMeta } from "/app/pages/containers/[id]/index.vue?macro=true";
import { default as indexB3jQOCnY4jMeta } from "/app/pages/containers/[id]/rare-items/index.vue?macro=true";
import { default as discordi7zcv5VAvhMeta } from "/app/pages/discord.vue?macro=true";
import { default as globusYUo3ZeMpdnMeta } from "/app/pages/globus.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as index9MRB9CXQInMeta } from "/app/pages/inventory/index.vue?macro=true";
import { default as _91exterior_93Kz5FGXAMwnMeta } from "/app/pages/item/[name]/[exterior].vue?macro=true";
import { default as indexBRvqtPPH1mMeta } from "/app/pages/item/[name]/index.vue?macro=true";
import { default as _91name_934ummBhabUmMeta } from "/app/pages/markets/[name].vue?macro=true";
import { default as indexHjlLclUvOiMeta } from "/app/pages/markets/index.vue?macro=true";
import { default as _91id_93ON5AvIoNdVMeta } from "/app/pages/pro-players/[id].vue?macro=true";
import { default as searchfcnTL5fl5XMeta } from "/app/pages/search.vue?macro=true";
import { default as _91id_93JxqhD5yVW6Meta } from "/app/pages/teams/[id].vue?macro=true";
import { default as indexstJWGRDdIoMeta } from "/app/pages/teams/index.vue?macro=true";
import { default as _91category_93i6uJdxH5efMeta } from "/app/pages/tournaments/[id]/[category].vue?macro=true";
import { default as _91id_93EKr3F2dlEtMeta } from "/app/pages/tournaments/[id].vue?macro=true";
import { default as indexOwjgRhpVzwMeta } from "/app/pages/tournaments/index.vue?macro=true";
import { default as trade_45upznQxmV96zNMeta } from "/app/pages/trade-up.vue?macro=true";
import { default as _91id_93u5La0OMPSPMeta } from "/app/pages/weapons/[id].vue?macro=true";
import { default as _91category_93RElwnyPbjeMeta } from "/app/pages/weapons/all/[category].vue?macro=true";
export default [
  {
    name: "admin___ch",
    path: "/ch/admin",
    meta: admin0eiku7Jj1aMeta || {},
    component: () => import("/app/pages/admin.vue"),
    children: [
  {
    name: "admin-dynamic-slug___ch",
    path: "dynamic/:slug(.*)*",
    component: () => import("/app/pages/admin/dynamic/[...slug].vue")
  },
  {
    name: "admin-dynamic___ch",
    path: "dynamic",
    component: () => import("/app/pages/admin/dynamic/index.vue")
  },
  {
    name: "admin-dynamic-new___ch",
    path: "dynamic/new",
    component: () => import("/app/pages/admin/dynamic/new.vue")
  },
  {
    name: "admin-markets-name___ch",
    path: "markets/:name()",
    component: () => import("/app/pages/admin/markets/[name].vue")
  },
  {
    name: "admin-markets___ch",
    path: "markets",
    component: () => import("/app/pages/admin/markets/index.vue")
  },
  {
    name: "admin-markets-new___ch",
    path: "markets/new",
    component: () => import("/app/pages/admin/markets/new.vue")
  }
]
  },
  {
    name: "admin___da",
    path: "/da/admin",
    meta: admin0eiku7Jj1aMeta || {},
    component: () => import("/app/pages/admin.vue"),
    children: [
  {
    name: "admin-dynamic-slug___da",
    path: "dynamic/:slug(.*)*",
    component: () => import("/app/pages/admin/dynamic/[...slug].vue")
  },
  {
    name: "admin-dynamic___da",
    path: "dynamic",
    component: () => import("/app/pages/admin/dynamic/index.vue")
  },
  {
    name: "admin-dynamic-new___da",
    path: "dynamic/new",
    component: () => import("/app/pages/admin/dynamic/new.vue")
  },
  {
    name: "admin-markets-name___da",
    path: "markets/:name()",
    component: () => import("/app/pages/admin/markets/[name].vue")
  },
  {
    name: "admin-markets___da",
    path: "markets",
    component: () => import("/app/pages/admin/markets/index.vue")
  },
  {
    name: "admin-markets-new___da",
    path: "markets/new",
    component: () => import("/app/pages/admin/markets/new.vue")
  }
]
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: admin0eiku7Jj1aMeta || {},
    component: () => import("/app/pages/admin.vue"),
    children: [
  {
    name: "admin-dynamic-slug___de",
    path: "dynamic/:slug(.*)*",
    component: () => import("/app/pages/admin/dynamic/[...slug].vue")
  },
  {
    name: "admin-dynamic___de",
    path: "dynamic",
    component: () => import("/app/pages/admin/dynamic/index.vue")
  },
  {
    name: "admin-dynamic-new___de",
    path: "dynamic/new",
    component: () => import("/app/pages/admin/dynamic/new.vue")
  },
  {
    name: "admin-markets-name___de",
    path: "markets/:name()",
    component: () => import("/app/pages/admin/markets/[name].vue")
  },
  {
    name: "admin-markets___de",
    path: "markets",
    component: () => import("/app/pages/admin/markets/index.vue")
  },
  {
    name: "admin-markets-new___de",
    path: "markets/new",
    component: () => import("/app/pages/admin/markets/new.vue")
  }
]
  },
  {
    name: "admin___en",
    path: "/admin",
    meta: admin0eiku7Jj1aMeta || {},
    component: () => import("/app/pages/admin.vue"),
    children: [
  {
    name: "admin-dynamic-slug___en",
    path: "dynamic/:slug(.*)*",
    component: () => import("/app/pages/admin/dynamic/[...slug].vue")
  },
  {
    name: "admin-dynamic___en",
    path: "dynamic",
    component: () => import("/app/pages/admin/dynamic/index.vue")
  },
  {
    name: "admin-dynamic-new___en",
    path: "dynamic/new",
    component: () => import("/app/pages/admin/dynamic/new.vue")
  },
  {
    name: "admin-markets-name___en",
    path: "markets/:name()",
    component: () => import("/app/pages/admin/markets/[name].vue")
  },
  {
    name: "admin-markets___en",
    path: "markets",
    component: () => import("/app/pages/admin/markets/index.vue")
  },
  {
    name: "admin-markets-new___en",
    path: "markets/new",
    component: () => import("/app/pages/admin/markets/new.vue")
  }
]
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: admin0eiku7Jj1aMeta || {},
    component: () => import("/app/pages/admin.vue"),
    children: [
  {
    name: "admin-dynamic-slug___es",
    path: "dynamic/:slug(.*)*",
    component: () => import("/app/pages/admin/dynamic/[...slug].vue")
  },
  {
    name: "admin-dynamic___es",
    path: "dynamic",
    component: () => import("/app/pages/admin/dynamic/index.vue")
  },
  {
    name: "admin-dynamic-new___es",
    path: "dynamic/new",
    component: () => import("/app/pages/admin/dynamic/new.vue")
  },
  {
    name: "admin-markets-name___es",
    path: "markets/:name()",
    component: () => import("/app/pages/admin/markets/[name].vue")
  },
  {
    name: "admin-markets___es",
    path: "markets",
    component: () => import("/app/pages/admin/markets/index.vue")
  },
  {
    name: "admin-markets-new___es",
    path: "markets/new",
    component: () => import("/app/pages/admin/markets/new.vue")
  }
]
  },
  {
    name: "admin___fr",
    path: "/fr/admin",
    meta: admin0eiku7Jj1aMeta || {},
    component: () => import("/app/pages/admin.vue"),
    children: [
  {
    name: "admin-dynamic-slug___fr",
    path: "dynamic/:slug(.*)*",
    component: () => import("/app/pages/admin/dynamic/[...slug].vue")
  },
  {
    name: "admin-dynamic___fr",
    path: "dynamic",
    component: () => import("/app/pages/admin/dynamic/index.vue")
  },
  {
    name: "admin-dynamic-new___fr",
    path: "dynamic/new",
    component: () => import("/app/pages/admin/dynamic/new.vue")
  },
  {
    name: "admin-markets-name___fr",
    path: "markets/:name()",
    component: () => import("/app/pages/admin/markets/[name].vue")
  },
  {
    name: "admin-markets___fr",
    path: "markets",
    component: () => import("/app/pages/admin/markets/index.vue")
  },
  {
    name: "admin-markets-new___fr",
    path: "markets/new",
    component: () => import("/app/pages/admin/markets/new.vue")
  }
]
  },
  {
    name: "admin___nl",
    path: "/nl/admin",
    meta: admin0eiku7Jj1aMeta || {},
    component: () => import("/app/pages/admin.vue"),
    children: [
  {
    name: "admin-dynamic-slug___nl",
    path: "dynamic/:slug(.*)*",
    component: () => import("/app/pages/admin/dynamic/[...slug].vue")
  },
  {
    name: "admin-dynamic___nl",
    path: "dynamic",
    component: () => import("/app/pages/admin/dynamic/index.vue")
  },
  {
    name: "admin-dynamic-new___nl",
    path: "dynamic/new",
    component: () => import("/app/pages/admin/dynamic/new.vue")
  },
  {
    name: "admin-markets-name___nl",
    path: "markets/:name()",
    component: () => import("/app/pages/admin/markets/[name].vue")
  },
  {
    name: "admin-markets___nl",
    path: "markets",
    component: () => import("/app/pages/admin/markets/index.vue")
  },
  {
    name: "admin-markets-new___nl",
    path: "markets/new",
    component: () => import("/app/pages/admin/markets/new.vue")
  }
]
  },
  {
    name: "admin___pl",
    path: "/pl/admin",
    meta: admin0eiku7Jj1aMeta || {},
    component: () => import("/app/pages/admin.vue"),
    children: [
  {
    name: "admin-dynamic-slug___pl",
    path: "dynamic/:slug(.*)*",
    component: () => import("/app/pages/admin/dynamic/[...slug].vue")
  },
  {
    name: "admin-dynamic___pl",
    path: "dynamic",
    component: () => import("/app/pages/admin/dynamic/index.vue")
  },
  {
    name: "admin-dynamic-new___pl",
    path: "dynamic/new",
    component: () => import("/app/pages/admin/dynamic/new.vue")
  },
  {
    name: "admin-markets-name___pl",
    path: "markets/:name()",
    component: () => import("/app/pages/admin/markets/[name].vue")
  },
  {
    name: "admin-markets___pl",
    path: "markets",
    component: () => import("/app/pages/admin/markets/index.vue")
  },
  {
    name: "admin-markets-new___pl",
    path: "markets/new",
    component: () => import("/app/pages/admin/markets/new.vue")
  }
]
  },
  {
    name: "admin___pt",
    path: "/pt/admin",
    meta: admin0eiku7Jj1aMeta || {},
    component: () => import("/app/pages/admin.vue"),
    children: [
  {
    name: "admin-dynamic-slug___pt",
    path: "dynamic/:slug(.*)*",
    component: () => import("/app/pages/admin/dynamic/[...slug].vue")
  },
  {
    name: "admin-dynamic___pt",
    path: "dynamic",
    component: () => import("/app/pages/admin/dynamic/index.vue")
  },
  {
    name: "admin-dynamic-new___pt",
    path: "dynamic/new",
    component: () => import("/app/pages/admin/dynamic/new.vue")
  },
  {
    name: "admin-markets-name___pt",
    path: "markets/:name()",
    component: () => import("/app/pages/admin/markets/[name].vue")
  },
  {
    name: "admin-markets___pt",
    path: "markets",
    component: () => import("/app/pages/admin/markets/index.vue")
  },
  {
    name: "admin-markets-new___pt",
    path: "markets/new",
    component: () => import("/app/pages/admin/markets/new.vue")
  }
]
  },
  {
    name: "admin___ro",
    path: "/ro/admin",
    meta: admin0eiku7Jj1aMeta || {},
    component: () => import("/app/pages/admin.vue"),
    children: [
  {
    name: "admin-dynamic-slug___ro",
    path: "dynamic/:slug(.*)*",
    component: () => import("/app/pages/admin/dynamic/[...slug].vue")
  },
  {
    name: "admin-dynamic___ro",
    path: "dynamic",
    component: () => import("/app/pages/admin/dynamic/index.vue")
  },
  {
    name: "admin-dynamic-new___ro",
    path: "dynamic/new",
    component: () => import("/app/pages/admin/dynamic/new.vue")
  },
  {
    name: "admin-markets-name___ro",
    path: "markets/:name()",
    component: () => import("/app/pages/admin/markets/[name].vue")
  },
  {
    name: "admin-markets___ro",
    path: "markets",
    component: () => import("/app/pages/admin/markets/index.vue")
  },
  {
    name: "admin-markets-new___ro",
    path: "markets/new",
    component: () => import("/app/pages/admin/markets/new.vue")
  }
]
  },
  {
    name: "admin___ru",
    path: "/ru/admin",
    meta: admin0eiku7Jj1aMeta || {},
    component: () => import("/app/pages/admin.vue"),
    children: [
  {
    name: "admin-dynamic-slug___ru",
    path: "dynamic/:slug(.*)*",
    component: () => import("/app/pages/admin/dynamic/[...slug].vue")
  },
  {
    name: "admin-dynamic___ru",
    path: "dynamic",
    component: () => import("/app/pages/admin/dynamic/index.vue")
  },
  {
    name: "admin-dynamic-new___ru",
    path: "dynamic/new",
    component: () => import("/app/pages/admin/dynamic/new.vue")
  },
  {
    name: "admin-markets-name___ru",
    path: "markets/:name()",
    component: () => import("/app/pages/admin/markets/[name].vue")
  },
  {
    name: "admin-markets___ru",
    path: "markets",
    component: () => import("/app/pages/admin/markets/index.vue")
  },
  {
    name: "admin-markets-new___ru",
    path: "markets/new",
    component: () => import("/app/pages/admin/markets/new.vue")
  }
]
  },
  {
    name: "admin___sv",
    path: "/sv/admin",
    meta: admin0eiku7Jj1aMeta || {},
    component: () => import("/app/pages/admin.vue"),
    children: [
  {
    name: "admin-dynamic-slug___sv",
    path: "dynamic/:slug(.*)*",
    component: () => import("/app/pages/admin/dynamic/[...slug].vue")
  },
  {
    name: "admin-dynamic___sv",
    path: "dynamic",
    component: () => import("/app/pages/admin/dynamic/index.vue")
  },
  {
    name: "admin-dynamic-new___sv",
    path: "dynamic/new",
    component: () => import("/app/pages/admin/dynamic/new.vue")
  },
  {
    name: "admin-markets-name___sv",
    path: "markets/:name()",
    component: () => import("/app/pages/admin/markets/[name].vue")
  },
  {
    name: "admin-markets___sv",
    path: "markets",
    component: () => import("/app/pages/admin/markets/index.vue")
  },
  {
    name: "admin-markets-new___sv",
    path: "markets/new",
    component: () => import("/app/pages/admin/markets/new.vue")
  }
]
  },
  {
    name: "admin___tr",
    path: "/tr/admin",
    meta: admin0eiku7Jj1aMeta || {},
    component: () => import("/app/pages/admin.vue"),
    children: [
  {
    name: "admin-dynamic-slug___tr",
    path: "dynamic/:slug(.*)*",
    component: () => import("/app/pages/admin/dynamic/[...slug].vue")
  },
  {
    name: "admin-dynamic___tr",
    path: "dynamic",
    component: () => import("/app/pages/admin/dynamic/index.vue")
  },
  {
    name: "admin-dynamic-new___tr",
    path: "dynamic/new",
    component: () => import("/app/pages/admin/dynamic/new.vue")
  },
  {
    name: "admin-markets-name___tr",
    path: "markets/:name()",
    component: () => import("/app/pages/admin/markets/[name].vue")
  },
  {
    name: "admin-markets___tr",
    path: "markets",
    component: () => import("/app/pages/admin/markets/index.vue")
  },
  {
    name: "admin-markets-new___tr",
    path: "markets/new",
    component: () => import("/app/pages/admin/markets/new.vue")
  }
]
  },
  {
    name: "admin___uk",
    path: "/uk/admin",
    meta: admin0eiku7Jj1aMeta || {},
    component: () => import("/app/pages/admin.vue"),
    children: [
  {
    name: "admin-dynamic-slug___uk",
    path: "dynamic/:slug(.*)*",
    component: () => import("/app/pages/admin/dynamic/[...slug].vue")
  },
  {
    name: "admin-dynamic___uk",
    path: "dynamic",
    component: () => import("/app/pages/admin/dynamic/index.vue")
  },
  {
    name: "admin-dynamic-new___uk",
    path: "dynamic/new",
    component: () => import("/app/pages/admin/dynamic/new.vue")
  },
  {
    name: "admin-markets-name___uk",
    path: "markets/:name()",
    component: () => import("/app/pages/admin/markets/[name].vue")
  },
  {
    name: "admin-markets___uk",
    path: "markets",
    component: () => import("/app/pages/admin/markets/index.vue")
  },
  {
    name: "admin-markets-new___uk",
    path: "markets/new",
    component: () => import("/app/pages/admin/markets/new.vue")
  }
]
  },
  {
    name: "bargains-slug___ch",
    path: "/ch/bargains/:slug(.*)*",
    component: () => import("/app/pages/bargains/[...slug].vue")
  },
  {
    name: "bargains-slug___da",
    path: "/da/bargains/:slug(.*)*",
    component: () => import("/app/pages/bargains/[...slug].vue")
  },
  {
    name: "bargains-slug___de",
    path: "/de/bargains/:slug(.*)*",
    component: () => import("/app/pages/bargains/[...slug].vue")
  },
  {
    name: "bargains-slug___en",
    path: "/bargains/:slug(.*)*",
    component: () => import("/app/pages/bargains/[...slug].vue")
  },
  {
    name: "bargains-slug___es",
    path: "/es/bargains/:slug(.*)*",
    component: () => import("/app/pages/bargains/[...slug].vue")
  },
  {
    name: "bargains-slug___fr",
    path: "/fr/bargains/:slug(.*)*",
    component: () => import("/app/pages/bargains/[...slug].vue")
  },
  {
    name: "bargains-slug___nl",
    path: "/nl/bargains/:slug(.*)*",
    component: () => import("/app/pages/bargains/[...slug].vue")
  },
  {
    name: "bargains-slug___pl",
    path: "/pl/bargains/:slug(.*)*",
    component: () => import("/app/pages/bargains/[...slug].vue")
  },
  {
    name: "bargains-slug___pt",
    path: "/pt/bargains/:slug(.*)*",
    component: () => import("/app/pages/bargains/[...slug].vue")
  },
  {
    name: "bargains-slug___ro",
    path: "/ro/bargains/:slug(.*)*",
    component: () => import("/app/pages/bargains/[...slug].vue")
  },
  {
    name: "bargains-slug___ru",
    path: "/ru/bargains/:slug(.*)*",
    component: () => import("/app/pages/bargains/[...slug].vue")
  },
  {
    name: "bargains-slug___sv",
    path: "/sv/bargains/:slug(.*)*",
    component: () => import("/app/pages/bargains/[...slug].vue")
  },
  {
    name: "bargains-slug___tr",
    path: "/tr/bargains/:slug(.*)*",
    component: () => import("/app/pages/bargains/[...slug].vue")
  },
  {
    name: "bargains-slug___uk",
    path: "/uk/bargains/:slug(.*)*",
    component: () => import("/app/pages/bargains/[...slug].vue")
  },
  {
    name: "blog-category-link___ch",
    path: "/ch/blog/:category()/:link()",
    meta: indexWiIX7Nv00WMeta || {},
    component: () => import("/app/pages/blog/[category]/[link]/index.vue")
  },
  {
    name: "blog-category-link___da",
    path: "/da/blog/:category()/:link()",
    meta: indexWiIX7Nv00WMeta || {},
    component: () => import("/app/pages/blog/[category]/[link]/index.vue")
  },
  {
    name: "blog-category-link___de",
    path: "/de/blog/:category()/:link()",
    meta: indexWiIX7Nv00WMeta || {},
    component: () => import("/app/pages/blog/[category]/[link]/index.vue")
  },
  {
    name: "blog-category-link___en",
    path: "/blog/:category()/:link()",
    meta: indexWiIX7Nv00WMeta || {},
    component: () => import("/app/pages/blog/[category]/[link]/index.vue")
  },
  {
    name: "blog-category-link___es",
    path: "/es/blog/:category()/:link()",
    meta: indexWiIX7Nv00WMeta || {},
    component: () => import("/app/pages/blog/[category]/[link]/index.vue")
  },
  {
    name: "blog-category-link___fr",
    path: "/fr/blog/:category()/:link()",
    meta: indexWiIX7Nv00WMeta || {},
    component: () => import("/app/pages/blog/[category]/[link]/index.vue")
  },
  {
    name: "blog-category-link___nl",
    path: "/nl/blog/:category()/:link()",
    meta: indexWiIX7Nv00WMeta || {},
    component: () => import("/app/pages/blog/[category]/[link]/index.vue")
  },
  {
    name: "blog-category-link___pl",
    path: "/pl/blog/:category()/:link()",
    meta: indexWiIX7Nv00WMeta || {},
    component: () => import("/app/pages/blog/[category]/[link]/index.vue")
  },
  {
    name: "blog-category-link___pt",
    path: "/pt/blog/:category()/:link()",
    meta: indexWiIX7Nv00WMeta || {},
    component: () => import("/app/pages/blog/[category]/[link]/index.vue")
  },
  {
    name: "blog-category-link___ro",
    path: "/ro/blog/:category()/:link()",
    meta: indexWiIX7Nv00WMeta || {},
    component: () => import("/app/pages/blog/[category]/[link]/index.vue")
  },
  {
    name: "blog-category-link___ru",
    path: "/ru/blog/:category()/:link()",
    meta: indexWiIX7Nv00WMeta || {},
    component: () => import("/app/pages/blog/[category]/[link]/index.vue")
  },
  {
    name: "blog-category-link___sv",
    path: "/sv/blog/:category()/:link()",
    meta: indexWiIX7Nv00WMeta || {},
    component: () => import("/app/pages/blog/[category]/[link]/index.vue")
  },
  {
    name: "blog-category-link___tr",
    path: "/tr/blog/:category()/:link()",
    meta: indexWiIX7Nv00WMeta || {},
    component: () => import("/app/pages/blog/[category]/[link]/index.vue")
  },
  {
    name: "blog-category-link___uk",
    path: "/uk/blog/:category()/:link()",
    meta: indexWiIX7Nv00WMeta || {},
    component: () => import("/app/pages/blog/[category]/[link]/index.vue")
  },
  {
    name: "blog-category___ch",
    path: "/ch/blog/:category()",
    meta: indexLnW0bqcI2xMeta || {},
    component: () => import("/app/pages/blog/[category]/index.vue")
  },
  {
    name: "blog-category___da",
    path: "/da/blog/:category()",
    meta: indexLnW0bqcI2xMeta || {},
    component: () => import("/app/pages/blog/[category]/index.vue")
  },
  {
    name: "blog-category___de",
    path: "/de/blog/:category()",
    meta: indexLnW0bqcI2xMeta || {},
    component: () => import("/app/pages/blog/[category]/index.vue")
  },
  {
    name: "blog-category___en",
    path: "/blog/:category()",
    meta: indexLnW0bqcI2xMeta || {},
    component: () => import("/app/pages/blog/[category]/index.vue")
  },
  {
    name: "blog-category___es",
    path: "/es/blog/:category()",
    meta: indexLnW0bqcI2xMeta || {},
    component: () => import("/app/pages/blog/[category]/index.vue")
  },
  {
    name: "blog-category___fr",
    path: "/fr/blog/:category()",
    meta: indexLnW0bqcI2xMeta || {},
    component: () => import("/app/pages/blog/[category]/index.vue")
  },
  {
    name: "blog-category___nl",
    path: "/nl/blog/:category()",
    meta: indexLnW0bqcI2xMeta || {},
    component: () => import("/app/pages/blog/[category]/index.vue")
  },
  {
    name: "blog-category___pl",
    path: "/pl/blog/:category()",
    meta: indexLnW0bqcI2xMeta || {},
    component: () => import("/app/pages/blog/[category]/index.vue")
  },
  {
    name: "blog-category___pt",
    path: "/pt/blog/:category()",
    meta: indexLnW0bqcI2xMeta || {},
    component: () => import("/app/pages/blog/[category]/index.vue")
  },
  {
    name: "blog-category___ro",
    path: "/ro/blog/:category()",
    meta: indexLnW0bqcI2xMeta || {},
    component: () => import("/app/pages/blog/[category]/index.vue")
  },
  {
    name: "blog-category___ru",
    path: "/ru/blog/:category()",
    meta: indexLnW0bqcI2xMeta || {},
    component: () => import("/app/pages/blog/[category]/index.vue")
  },
  {
    name: "blog-category___sv",
    path: "/sv/blog/:category()",
    meta: indexLnW0bqcI2xMeta || {},
    component: () => import("/app/pages/blog/[category]/index.vue")
  },
  {
    name: "blog-category___tr",
    path: "/tr/blog/:category()",
    meta: indexLnW0bqcI2xMeta || {},
    component: () => import("/app/pages/blog/[category]/index.vue")
  },
  {
    name: "blog-category___uk",
    path: "/uk/blog/:category()",
    meta: indexLnW0bqcI2xMeta || {},
    component: () => import("/app/pages/blog/[category]/index.vue")
  },
  {
    name: "blog___ch",
    path: "/ch/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___da",
    path: "/da/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___de",
    path: "/de/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___en",
    path: "/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___es",
    path: "/es/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___fr",
    path: "/fr/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___nl",
    path: "/nl/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___pl",
    path: "/pl/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___pt",
    path: "/pt/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___ro",
    path: "/ro/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___ru",
    path: "/ru/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___sv",
    path: "/sv/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___tr",
    path: "/tr/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___uk",
    path: "/uk/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog-moderation-edit-category-link___ch",
    path: "/ch/blog/moderation/edit/:category()/:link()",
    meta: _91link_93V33zwQ8ZK7Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/[link].vue")
  },
  {
    name: "blog-moderation-edit-category-link___da",
    path: "/da/blog/moderation/edit/:category()/:link()",
    meta: _91link_93V33zwQ8ZK7Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/[link].vue")
  },
  {
    name: "blog-moderation-edit-category-link___de",
    path: "/de/blog/moderation/edit/:category()/:link()",
    meta: _91link_93V33zwQ8ZK7Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/[link].vue")
  },
  {
    name: "blog-moderation-edit-category-link___en",
    path: "/blog/moderation/edit/:category()/:link()",
    meta: _91link_93V33zwQ8ZK7Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/[link].vue")
  },
  {
    name: "blog-moderation-edit-category-link___es",
    path: "/es/blog/moderation/edit/:category()/:link()",
    meta: _91link_93V33zwQ8ZK7Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/[link].vue")
  },
  {
    name: "blog-moderation-edit-category-link___fr",
    path: "/fr/blog/moderation/edit/:category()/:link()",
    meta: _91link_93V33zwQ8ZK7Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/[link].vue")
  },
  {
    name: "blog-moderation-edit-category-link___nl",
    path: "/nl/blog/moderation/edit/:category()/:link()",
    meta: _91link_93V33zwQ8ZK7Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/[link].vue")
  },
  {
    name: "blog-moderation-edit-category-link___pl",
    path: "/pl/blog/moderation/edit/:category()/:link()",
    meta: _91link_93V33zwQ8ZK7Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/[link].vue")
  },
  {
    name: "blog-moderation-edit-category-link___pt",
    path: "/pt/blog/moderation/edit/:category()/:link()",
    meta: _91link_93V33zwQ8ZK7Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/[link].vue")
  },
  {
    name: "blog-moderation-edit-category-link___ro",
    path: "/ro/blog/moderation/edit/:category()/:link()",
    meta: _91link_93V33zwQ8ZK7Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/[link].vue")
  },
  {
    name: "blog-moderation-edit-category-link___ru",
    path: "/ru/blog/moderation/edit/:category()/:link()",
    meta: _91link_93V33zwQ8ZK7Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/[link].vue")
  },
  {
    name: "blog-moderation-edit-category-link___sv",
    path: "/sv/blog/moderation/edit/:category()/:link()",
    meta: _91link_93V33zwQ8ZK7Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/[link].vue")
  },
  {
    name: "blog-moderation-edit-category-link___tr",
    path: "/tr/blog/moderation/edit/:category()/:link()",
    meta: _91link_93V33zwQ8ZK7Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/[link].vue")
  },
  {
    name: "blog-moderation-edit-category-link___uk",
    path: "/uk/blog/moderation/edit/:category()/:link()",
    meta: _91link_93V33zwQ8ZK7Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/[link].vue")
  },
  {
    name: "blog-moderation-edit-category___ch",
    path: "/ch/blog/moderation/edit/:category()",
    meta: indexL1ZOmhUMP0Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/index.vue")
  },
  {
    name: "blog-moderation-edit-category___da",
    path: "/da/blog/moderation/edit/:category()",
    meta: indexL1ZOmhUMP0Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/index.vue")
  },
  {
    name: "blog-moderation-edit-category___de",
    path: "/de/blog/moderation/edit/:category()",
    meta: indexL1ZOmhUMP0Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/index.vue")
  },
  {
    name: "blog-moderation-edit-category___en",
    path: "/blog/moderation/edit/:category()",
    meta: indexL1ZOmhUMP0Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/index.vue")
  },
  {
    name: "blog-moderation-edit-category___es",
    path: "/es/blog/moderation/edit/:category()",
    meta: indexL1ZOmhUMP0Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/index.vue")
  },
  {
    name: "blog-moderation-edit-category___fr",
    path: "/fr/blog/moderation/edit/:category()",
    meta: indexL1ZOmhUMP0Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/index.vue")
  },
  {
    name: "blog-moderation-edit-category___nl",
    path: "/nl/blog/moderation/edit/:category()",
    meta: indexL1ZOmhUMP0Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/index.vue")
  },
  {
    name: "blog-moderation-edit-category___pl",
    path: "/pl/blog/moderation/edit/:category()",
    meta: indexL1ZOmhUMP0Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/index.vue")
  },
  {
    name: "blog-moderation-edit-category___pt",
    path: "/pt/blog/moderation/edit/:category()",
    meta: indexL1ZOmhUMP0Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/index.vue")
  },
  {
    name: "blog-moderation-edit-category___ro",
    path: "/ro/blog/moderation/edit/:category()",
    meta: indexL1ZOmhUMP0Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/index.vue")
  },
  {
    name: "blog-moderation-edit-category___ru",
    path: "/ru/blog/moderation/edit/:category()",
    meta: indexL1ZOmhUMP0Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/index.vue")
  },
  {
    name: "blog-moderation-edit-category___sv",
    path: "/sv/blog/moderation/edit/:category()",
    meta: indexL1ZOmhUMP0Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/index.vue")
  },
  {
    name: "blog-moderation-edit-category___tr",
    path: "/tr/blog/moderation/edit/:category()",
    meta: indexL1ZOmhUMP0Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/index.vue")
  },
  {
    name: "blog-moderation-edit-category___uk",
    path: "/uk/blog/moderation/edit/:category()",
    meta: indexL1ZOmhUMP0Meta || {},
    component: () => import("/app/pages/blog/moderation/edit/[category]/index.vue")
  },
  {
    name: "blog-moderation___ch",
    path: "/ch/blog/moderation",
    meta: index5Gp0FVnsX3Meta || {},
    component: () => import("/app/pages/blog/moderation/index.vue")
  },
  {
    name: "blog-moderation___da",
    path: "/da/blog/moderation",
    meta: index5Gp0FVnsX3Meta || {},
    component: () => import("/app/pages/blog/moderation/index.vue")
  },
  {
    name: "blog-moderation___de",
    path: "/de/blog/moderation",
    meta: index5Gp0FVnsX3Meta || {},
    component: () => import("/app/pages/blog/moderation/index.vue")
  },
  {
    name: "blog-moderation___en",
    path: "/blog/moderation",
    meta: index5Gp0FVnsX3Meta || {},
    component: () => import("/app/pages/blog/moderation/index.vue")
  },
  {
    name: "blog-moderation___es",
    path: "/es/blog/moderation",
    meta: index5Gp0FVnsX3Meta || {},
    component: () => import("/app/pages/blog/moderation/index.vue")
  },
  {
    name: "blog-moderation___fr",
    path: "/fr/blog/moderation",
    meta: index5Gp0FVnsX3Meta || {},
    component: () => import("/app/pages/blog/moderation/index.vue")
  },
  {
    name: "blog-moderation___nl",
    path: "/nl/blog/moderation",
    meta: index5Gp0FVnsX3Meta || {},
    component: () => import("/app/pages/blog/moderation/index.vue")
  },
  {
    name: "blog-moderation___pl",
    path: "/pl/blog/moderation",
    meta: index5Gp0FVnsX3Meta || {},
    component: () => import("/app/pages/blog/moderation/index.vue")
  },
  {
    name: "blog-moderation___pt",
    path: "/pt/blog/moderation",
    meta: index5Gp0FVnsX3Meta || {},
    component: () => import("/app/pages/blog/moderation/index.vue")
  },
  {
    name: "blog-moderation___ro",
    path: "/ro/blog/moderation",
    meta: index5Gp0FVnsX3Meta || {},
    component: () => import("/app/pages/blog/moderation/index.vue")
  },
  {
    name: "blog-moderation___ru",
    path: "/ru/blog/moderation",
    meta: index5Gp0FVnsX3Meta || {},
    component: () => import("/app/pages/blog/moderation/index.vue")
  },
  {
    name: "blog-moderation___sv",
    path: "/sv/blog/moderation",
    meta: index5Gp0FVnsX3Meta || {},
    component: () => import("/app/pages/blog/moderation/index.vue")
  },
  {
    name: "blog-moderation___tr",
    path: "/tr/blog/moderation",
    meta: index5Gp0FVnsX3Meta || {},
    component: () => import("/app/pages/blog/moderation/index.vue")
  },
  {
    name: "blog-moderation___uk",
    path: "/uk/blog/moderation",
    meta: index5Gp0FVnsX3Meta || {},
    component: () => import("/app/pages/blog/moderation/index.vue")
  },
  {
    name: "blog-moderation-new___ch",
    path: "/ch/blog/moderation/new",
    meta: newaLo2T0Xve9Meta || {},
    component: () => import("/app/pages/blog/moderation/new.vue")
  },
  {
    name: "blog-moderation-new___da",
    path: "/da/blog/moderation/new",
    meta: newaLo2T0Xve9Meta || {},
    component: () => import("/app/pages/blog/moderation/new.vue")
  },
  {
    name: "blog-moderation-new___de",
    path: "/de/blog/moderation/new",
    meta: newaLo2T0Xve9Meta || {},
    component: () => import("/app/pages/blog/moderation/new.vue")
  },
  {
    name: "blog-moderation-new___en",
    path: "/blog/moderation/new",
    meta: newaLo2T0Xve9Meta || {},
    component: () => import("/app/pages/blog/moderation/new.vue")
  },
  {
    name: "blog-moderation-new___es",
    path: "/es/blog/moderation/new",
    meta: newaLo2T0Xve9Meta || {},
    component: () => import("/app/pages/blog/moderation/new.vue")
  },
  {
    name: "blog-moderation-new___fr",
    path: "/fr/blog/moderation/new",
    meta: newaLo2T0Xve9Meta || {},
    component: () => import("/app/pages/blog/moderation/new.vue")
  },
  {
    name: "blog-moderation-new___nl",
    path: "/nl/blog/moderation/new",
    meta: newaLo2T0Xve9Meta || {},
    component: () => import("/app/pages/blog/moderation/new.vue")
  },
  {
    name: "blog-moderation-new___pl",
    path: "/pl/blog/moderation/new",
    meta: newaLo2T0Xve9Meta || {},
    component: () => import("/app/pages/blog/moderation/new.vue")
  },
  {
    name: "blog-moderation-new___pt",
    path: "/pt/blog/moderation/new",
    meta: newaLo2T0Xve9Meta || {},
    component: () => import("/app/pages/blog/moderation/new.vue")
  },
  {
    name: "blog-moderation-new___ro",
    path: "/ro/blog/moderation/new",
    meta: newaLo2T0Xve9Meta || {},
    component: () => import("/app/pages/blog/moderation/new.vue")
  },
  {
    name: "blog-moderation-new___ru",
    path: "/ru/blog/moderation/new",
    meta: newaLo2T0Xve9Meta || {},
    component: () => import("/app/pages/blog/moderation/new.vue")
  },
  {
    name: "blog-moderation-new___sv",
    path: "/sv/blog/moderation/new",
    meta: newaLo2T0Xve9Meta || {},
    component: () => import("/app/pages/blog/moderation/new.vue")
  },
  {
    name: "blog-moderation-new___tr",
    path: "/tr/blog/moderation/new",
    meta: newaLo2T0Xve9Meta || {},
    component: () => import("/app/pages/blog/moderation/new.vue")
  },
  {
    name: "blog-moderation-new___uk",
    path: "/uk/blog/moderation/new",
    meta: newaLo2T0Xve9Meta || {},
    component: () => import("/app/pages/blog/moderation/new.vue")
  },
  {
    name: "colors-id___ch",
    path: "/ch/colors/:id()",
    component: () => import("/app/pages/colors/[id].vue")
  },
  {
    name: "colors-id___da",
    path: "/da/colors/:id()",
    component: () => import("/app/pages/colors/[id].vue")
  },
  {
    name: "colors-id___de",
    path: "/de/colors/:id()",
    component: () => import("/app/pages/colors/[id].vue")
  },
  {
    name: "colors-id___en",
    path: "/colors/:id()",
    component: () => import("/app/pages/colors/[id].vue")
  },
  {
    name: "colors-id___es",
    path: "/es/colors/:id()",
    component: () => import("/app/pages/colors/[id].vue")
  },
  {
    name: "colors-id___fr",
    path: "/fr/colors/:id()",
    component: () => import("/app/pages/colors/[id].vue")
  },
  {
    name: "colors-id___nl",
    path: "/nl/colors/:id()",
    component: () => import("/app/pages/colors/[id].vue")
  },
  {
    name: "colors-id___pl",
    path: "/pl/colors/:id()",
    component: () => import("/app/pages/colors/[id].vue")
  },
  {
    name: "colors-id___pt",
    path: "/pt/colors/:id()",
    component: () => import("/app/pages/colors/[id].vue")
  },
  {
    name: "colors-id___ro",
    path: "/ro/colors/:id()",
    component: () => import("/app/pages/colors/[id].vue")
  },
  {
    name: "colors-id___ru",
    path: "/ru/colors/:id()",
    component: () => import("/app/pages/colors/[id].vue")
  },
  {
    name: "colors-id___sv",
    path: "/sv/colors/:id()",
    component: () => import("/app/pages/colors/[id].vue")
  },
  {
    name: "colors-id___tr",
    path: "/tr/colors/:id()",
    component: () => import("/app/pages/colors/[id].vue")
  },
  {
    name: "colors-id___uk",
    path: "/uk/colors/:id()",
    component: () => import("/app/pages/colors/[id].vue")
  },
  {
    name: "colors___ch",
    path: "/ch/colors",
    component: () => import("/app/pages/colors/index.vue")
  },
  {
    name: "colors___da",
    path: "/da/colors",
    component: () => import("/app/pages/colors/index.vue")
  },
  {
    name: "colors___de",
    path: "/de/colors",
    component: () => import("/app/pages/colors/index.vue")
  },
  {
    name: "colors___en",
    path: "/colors",
    component: () => import("/app/pages/colors/index.vue")
  },
  {
    name: "colors___es",
    path: "/es/colors",
    component: () => import("/app/pages/colors/index.vue")
  },
  {
    name: "colors___fr",
    path: "/fr/colors",
    component: () => import("/app/pages/colors/index.vue")
  },
  {
    name: "colors___nl",
    path: "/nl/colors",
    component: () => import("/app/pages/colors/index.vue")
  },
  {
    name: "colors___pl",
    path: "/pl/colors",
    component: () => import("/app/pages/colors/index.vue")
  },
  {
    name: "colors___pt",
    path: "/pt/colors",
    component: () => import("/app/pages/colors/index.vue")
  },
  {
    name: "colors___ro",
    path: "/ro/colors",
    component: () => import("/app/pages/colors/index.vue")
  },
  {
    name: "colors___ru",
    path: "/ru/colors",
    component: () => import("/app/pages/colors/index.vue")
  },
  {
    name: "colors___sv",
    path: "/sv/colors",
    component: () => import("/app/pages/colors/index.vue")
  },
  {
    name: "colors___tr",
    path: "/tr/colors",
    component: () => import("/app/pages/colors/index.vue")
  },
  {
    name: "colors___uk",
    path: "/uk/colors",
    component: () => import("/app/pages/colors/index.vue")
  },
  {
    name: "comparison___ch",
    path: "/ch/comparison",
    meta: comparisonOPMyBPcTFrMeta || {},
    component: () => import("/app/pages/comparison.vue")
  },
  {
    name: "comparison___da",
    path: "/da/comparison",
    meta: comparisonOPMyBPcTFrMeta || {},
    component: () => import("/app/pages/comparison.vue")
  },
  {
    name: "comparison___de",
    path: "/de/comparison",
    meta: comparisonOPMyBPcTFrMeta || {},
    component: () => import("/app/pages/comparison.vue")
  },
  {
    name: "comparison___en",
    path: "/comparison",
    meta: comparisonOPMyBPcTFrMeta || {},
    component: () => import("/app/pages/comparison.vue")
  },
  {
    name: "comparison___es",
    path: "/es/comparison",
    meta: comparisonOPMyBPcTFrMeta || {},
    component: () => import("/app/pages/comparison.vue")
  },
  {
    name: "comparison___fr",
    path: "/fr/comparison",
    meta: comparisonOPMyBPcTFrMeta || {},
    component: () => import("/app/pages/comparison.vue")
  },
  {
    name: "comparison___nl",
    path: "/nl/comparison",
    meta: comparisonOPMyBPcTFrMeta || {},
    component: () => import("/app/pages/comparison.vue")
  },
  {
    name: "comparison___pl",
    path: "/pl/comparison",
    meta: comparisonOPMyBPcTFrMeta || {},
    component: () => import("/app/pages/comparison.vue")
  },
  {
    name: "comparison___pt",
    path: "/pt/comparison",
    meta: comparisonOPMyBPcTFrMeta || {},
    component: () => import("/app/pages/comparison.vue")
  },
  {
    name: "comparison___ro",
    path: "/ro/comparison",
    meta: comparisonOPMyBPcTFrMeta || {},
    component: () => import("/app/pages/comparison.vue")
  },
  {
    name: "comparison___ru",
    path: "/ru/comparison",
    meta: comparisonOPMyBPcTFrMeta || {},
    component: () => import("/app/pages/comparison.vue")
  },
  {
    name: "comparison___sv",
    path: "/sv/comparison",
    meta: comparisonOPMyBPcTFrMeta || {},
    component: () => import("/app/pages/comparison.vue")
  },
  {
    name: "comparison___tr",
    path: "/tr/comparison",
    meta: comparisonOPMyBPcTFrMeta || {},
    component: () => import("/app/pages/comparison.vue")
  },
  {
    name: "comparison___uk",
    path: "/uk/comparison",
    meta: comparisonOPMyBPcTFrMeta || {},
    component: () => import("/app/pages/comparison.vue")
  },
  {
    name: "containers-id___ch",
    path: "/ch/containers/:id()",
    component: () => import("/app/pages/containers/[id]/index.vue")
  },
  {
    name: "containers-id___da",
    path: "/da/containers/:id()",
    component: () => import("/app/pages/containers/[id]/index.vue")
  },
  {
    name: "containers-id___de",
    path: "/de/containers/:id()",
    component: () => import("/app/pages/containers/[id]/index.vue")
  },
  {
    name: "containers-id___en",
    path: "/containers/:id()",
    component: () => import("/app/pages/containers/[id]/index.vue")
  },
  {
    name: "containers-id___es",
    path: "/es/containers/:id()",
    component: () => import("/app/pages/containers/[id]/index.vue")
  },
  {
    name: "containers-id___fr",
    path: "/fr/containers/:id()",
    component: () => import("/app/pages/containers/[id]/index.vue")
  },
  {
    name: "containers-id___nl",
    path: "/nl/containers/:id()",
    component: () => import("/app/pages/containers/[id]/index.vue")
  },
  {
    name: "containers-id___pl",
    path: "/pl/containers/:id()",
    component: () => import("/app/pages/containers/[id]/index.vue")
  },
  {
    name: "containers-id___pt",
    path: "/pt/containers/:id()",
    component: () => import("/app/pages/containers/[id]/index.vue")
  },
  {
    name: "containers-id___ro",
    path: "/ro/containers/:id()",
    component: () => import("/app/pages/containers/[id]/index.vue")
  },
  {
    name: "containers-id___ru",
    path: "/ru/containers/:id()",
    component: () => import("/app/pages/containers/[id]/index.vue")
  },
  {
    name: "containers-id___sv",
    path: "/sv/containers/:id()",
    component: () => import("/app/pages/containers/[id]/index.vue")
  },
  {
    name: "containers-id___tr",
    path: "/tr/containers/:id()",
    component: () => import("/app/pages/containers/[id]/index.vue")
  },
  {
    name: "containers-id___uk",
    path: "/uk/containers/:id()",
    component: () => import("/app/pages/containers/[id]/index.vue")
  },
  {
    name: "containers-id-rare-items___ch",
    path: "/ch/containers/:id()/rare-items",
    component: () => import("/app/pages/containers/[id]/rare-items/index.vue")
  },
  {
    name: "containers-id-rare-items___da",
    path: "/da/containers/:id()/rare-items",
    component: () => import("/app/pages/containers/[id]/rare-items/index.vue")
  },
  {
    name: "containers-id-rare-items___de",
    path: "/de/containers/:id()/rare-items",
    component: () => import("/app/pages/containers/[id]/rare-items/index.vue")
  },
  {
    name: "containers-id-rare-items___en",
    path: "/containers/:id()/rare-items",
    component: () => import("/app/pages/containers/[id]/rare-items/index.vue")
  },
  {
    name: "containers-id-rare-items___es",
    path: "/es/containers/:id()/rare-items",
    component: () => import("/app/pages/containers/[id]/rare-items/index.vue")
  },
  {
    name: "containers-id-rare-items___fr",
    path: "/fr/containers/:id()/rare-items",
    component: () => import("/app/pages/containers/[id]/rare-items/index.vue")
  },
  {
    name: "containers-id-rare-items___nl",
    path: "/nl/containers/:id()/rare-items",
    component: () => import("/app/pages/containers/[id]/rare-items/index.vue")
  },
  {
    name: "containers-id-rare-items___pl",
    path: "/pl/containers/:id()/rare-items",
    component: () => import("/app/pages/containers/[id]/rare-items/index.vue")
  },
  {
    name: "containers-id-rare-items___pt",
    path: "/pt/containers/:id()/rare-items",
    component: () => import("/app/pages/containers/[id]/rare-items/index.vue")
  },
  {
    name: "containers-id-rare-items___ro",
    path: "/ro/containers/:id()/rare-items",
    component: () => import("/app/pages/containers/[id]/rare-items/index.vue")
  },
  {
    name: "containers-id-rare-items___ru",
    path: "/ru/containers/:id()/rare-items",
    component: () => import("/app/pages/containers/[id]/rare-items/index.vue")
  },
  {
    name: "containers-id-rare-items___sv",
    path: "/sv/containers/:id()/rare-items",
    component: () => import("/app/pages/containers/[id]/rare-items/index.vue")
  },
  {
    name: "containers-id-rare-items___tr",
    path: "/tr/containers/:id()/rare-items",
    component: () => import("/app/pages/containers/[id]/rare-items/index.vue")
  },
  {
    name: "containers-id-rare-items___uk",
    path: "/uk/containers/:id()/rare-items",
    component: () => import("/app/pages/containers/[id]/rare-items/index.vue")
  },
  {
    name: "discord___ch",
    path: "/ch/discord",
    component: () => import("/app/pages/discord.vue")
  },
  {
    name: "discord___da",
    path: "/da/discord",
    component: () => import("/app/pages/discord.vue")
  },
  {
    name: "discord___de",
    path: "/de/discord",
    component: () => import("/app/pages/discord.vue")
  },
  {
    name: "discord___en",
    path: "/discord",
    component: () => import("/app/pages/discord.vue")
  },
  {
    name: "discord___es",
    path: "/es/discord",
    component: () => import("/app/pages/discord.vue")
  },
  {
    name: "discord___fr",
    path: "/fr/discord",
    component: () => import("/app/pages/discord.vue")
  },
  {
    name: "discord___nl",
    path: "/nl/discord",
    component: () => import("/app/pages/discord.vue")
  },
  {
    name: "discord___pl",
    path: "/pl/discord",
    component: () => import("/app/pages/discord.vue")
  },
  {
    name: "discord___pt",
    path: "/pt/discord",
    component: () => import("/app/pages/discord.vue")
  },
  {
    name: "discord___ro",
    path: "/ro/discord",
    component: () => import("/app/pages/discord.vue")
  },
  {
    name: "discord___ru",
    path: "/ru/discord",
    component: () => import("/app/pages/discord.vue")
  },
  {
    name: "discord___sv",
    path: "/sv/discord",
    component: () => import("/app/pages/discord.vue")
  },
  {
    name: "discord___tr",
    path: "/tr/discord",
    component: () => import("/app/pages/discord.vue")
  },
  {
    name: "discord___uk",
    path: "/uk/discord",
    component: () => import("/app/pages/discord.vue")
  },
  {
    name: "globus___ch",
    path: "/ch/globus",
    meta: globusYUo3ZeMpdnMeta || {},
    component: () => import("/app/pages/globus.vue")
  },
  {
    name: "globus___da",
    path: "/da/globus",
    meta: globusYUo3ZeMpdnMeta || {},
    component: () => import("/app/pages/globus.vue")
  },
  {
    name: "globus___de",
    path: "/de/globus",
    meta: globusYUo3ZeMpdnMeta || {},
    component: () => import("/app/pages/globus.vue")
  },
  {
    name: "globus___en",
    path: "/globus",
    meta: globusYUo3ZeMpdnMeta || {},
    component: () => import("/app/pages/globus.vue")
  },
  {
    name: "globus___es",
    path: "/es/globus",
    meta: globusYUo3ZeMpdnMeta || {},
    component: () => import("/app/pages/globus.vue")
  },
  {
    name: "globus___fr",
    path: "/fr/globus",
    meta: globusYUo3ZeMpdnMeta || {},
    component: () => import("/app/pages/globus.vue")
  },
  {
    name: "globus___nl",
    path: "/nl/globus",
    meta: globusYUo3ZeMpdnMeta || {},
    component: () => import("/app/pages/globus.vue")
  },
  {
    name: "globus___pl",
    path: "/pl/globus",
    meta: globusYUo3ZeMpdnMeta || {},
    component: () => import("/app/pages/globus.vue")
  },
  {
    name: "globus___pt",
    path: "/pt/globus",
    meta: globusYUo3ZeMpdnMeta || {},
    component: () => import("/app/pages/globus.vue")
  },
  {
    name: "globus___ro",
    path: "/ro/globus",
    meta: globusYUo3ZeMpdnMeta || {},
    component: () => import("/app/pages/globus.vue")
  },
  {
    name: "globus___ru",
    path: "/ru/globus",
    meta: globusYUo3ZeMpdnMeta || {},
    component: () => import("/app/pages/globus.vue")
  },
  {
    name: "globus___sv",
    path: "/sv/globus",
    meta: globusYUo3ZeMpdnMeta || {},
    component: () => import("/app/pages/globus.vue")
  },
  {
    name: "globus___tr",
    path: "/tr/globus",
    meta: globusYUo3ZeMpdnMeta || {},
    component: () => import("/app/pages/globus.vue")
  },
  {
    name: "globus___uk",
    path: "/uk/globus",
    meta: globusYUo3ZeMpdnMeta || {},
    component: () => import("/app/pages/globus.vue")
  },
  {
    name: "index___ch",
    path: "/ch",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___da",
    path: "/da",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___pl",
    path: "/pl",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/pt",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___ro",
    path: "/ro",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___sv",
    path: "/sv",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___tr",
    path: "/tr",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___uk",
    path: "/uk",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "inventory___ch",
    path: "/ch/inventory",
    meta: index9MRB9CXQInMeta || {},
    component: () => import("/app/pages/inventory/index.vue")
  },
  {
    name: "inventory___da",
    path: "/da/inventory",
    meta: index9MRB9CXQInMeta || {},
    component: () => import("/app/pages/inventory/index.vue")
  },
  {
    name: "inventory___de",
    path: "/de/inventory",
    meta: index9MRB9CXQInMeta || {},
    component: () => import("/app/pages/inventory/index.vue")
  },
  {
    name: "inventory___en",
    path: "/inventory",
    meta: index9MRB9CXQInMeta || {},
    component: () => import("/app/pages/inventory/index.vue")
  },
  {
    name: "inventory___es",
    path: "/es/inventory",
    meta: index9MRB9CXQInMeta || {},
    component: () => import("/app/pages/inventory/index.vue")
  },
  {
    name: "inventory___fr",
    path: "/fr/inventory",
    meta: index9MRB9CXQInMeta || {},
    component: () => import("/app/pages/inventory/index.vue")
  },
  {
    name: "inventory___nl",
    path: "/nl/inventory",
    meta: index9MRB9CXQInMeta || {},
    component: () => import("/app/pages/inventory/index.vue")
  },
  {
    name: "inventory___pl",
    path: "/pl/inventory",
    meta: index9MRB9CXQInMeta || {},
    component: () => import("/app/pages/inventory/index.vue")
  },
  {
    name: "inventory___pt",
    path: "/pt/inventory",
    meta: index9MRB9CXQInMeta || {},
    component: () => import("/app/pages/inventory/index.vue")
  },
  {
    name: "inventory___ro",
    path: "/ro/inventory",
    meta: index9MRB9CXQInMeta || {},
    component: () => import("/app/pages/inventory/index.vue")
  },
  {
    name: "inventory___ru",
    path: "/ru/inventory",
    meta: index9MRB9CXQInMeta || {},
    component: () => import("/app/pages/inventory/index.vue")
  },
  {
    name: "inventory___sv",
    path: "/sv/inventory",
    meta: index9MRB9CXQInMeta || {},
    component: () => import("/app/pages/inventory/index.vue")
  },
  {
    name: "inventory___tr",
    path: "/tr/inventory",
    meta: index9MRB9CXQInMeta || {},
    component: () => import("/app/pages/inventory/index.vue")
  },
  {
    name: "inventory___uk",
    path: "/uk/inventory",
    meta: index9MRB9CXQInMeta || {},
    component: () => import("/app/pages/inventory/index.vue")
  },
  {
    name: "item-name-exterior___ch",
    path: "/ch/item/:name()/:exterior()",
    component: () => import("/app/pages/item/[name]/[exterior].vue")
  },
  {
    name: "item-name-exterior___da",
    path: "/da/item/:name()/:exterior()",
    component: () => import("/app/pages/item/[name]/[exterior].vue")
  },
  {
    name: "item-name-exterior___de",
    path: "/de/item/:name()/:exterior()",
    component: () => import("/app/pages/item/[name]/[exterior].vue")
  },
  {
    name: "item-name-exterior___en",
    path: "/item/:name()/:exterior()",
    component: () => import("/app/pages/item/[name]/[exterior].vue")
  },
  {
    name: "item-name-exterior___es",
    path: "/es/item/:name()/:exterior()",
    component: () => import("/app/pages/item/[name]/[exterior].vue")
  },
  {
    name: "item-name-exterior___fr",
    path: "/fr/item/:name()/:exterior()",
    component: () => import("/app/pages/item/[name]/[exterior].vue")
  },
  {
    name: "item-name-exterior___nl",
    path: "/nl/item/:name()/:exterior()",
    component: () => import("/app/pages/item/[name]/[exterior].vue")
  },
  {
    name: "item-name-exterior___pl",
    path: "/pl/item/:name()/:exterior()",
    component: () => import("/app/pages/item/[name]/[exterior].vue")
  },
  {
    name: "item-name-exterior___pt",
    path: "/pt/item/:name()/:exterior()",
    component: () => import("/app/pages/item/[name]/[exterior].vue")
  },
  {
    name: "item-name-exterior___ro",
    path: "/ro/item/:name()/:exterior()",
    component: () => import("/app/pages/item/[name]/[exterior].vue")
  },
  {
    name: "item-name-exterior___ru",
    path: "/ru/item/:name()/:exterior()",
    component: () => import("/app/pages/item/[name]/[exterior].vue")
  },
  {
    name: "item-name-exterior___sv",
    path: "/sv/item/:name()/:exterior()",
    component: () => import("/app/pages/item/[name]/[exterior].vue")
  },
  {
    name: "item-name-exterior___tr",
    path: "/tr/item/:name()/:exterior()",
    component: () => import("/app/pages/item/[name]/[exterior].vue")
  },
  {
    name: "item-name-exterior___uk",
    path: "/uk/item/:name()/:exterior()",
    component: () => import("/app/pages/item/[name]/[exterior].vue")
  },
  {
    name: "item-name___ch",
    path: "/ch/item/:name()",
    component: () => import("/app/pages/item/[name]/index.vue")
  },
  {
    name: "item-name___da",
    path: "/da/item/:name()",
    component: () => import("/app/pages/item/[name]/index.vue")
  },
  {
    name: "item-name___de",
    path: "/de/item/:name()",
    component: () => import("/app/pages/item/[name]/index.vue")
  },
  {
    name: "item-name___en",
    path: "/item/:name()",
    component: () => import("/app/pages/item/[name]/index.vue")
  },
  {
    name: "item-name___es",
    path: "/es/item/:name()",
    component: () => import("/app/pages/item/[name]/index.vue")
  },
  {
    name: "item-name___fr",
    path: "/fr/item/:name()",
    component: () => import("/app/pages/item/[name]/index.vue")
  },
  {
    name: "item-name___nl",
    path: "/nl/item/:name()",
    component: () => import("/app/pages/item/[name]/index.vue")
  },
  {
    name: "item-name___pl",
    path: "/pl/item/:name()",
    component: () => import("/app/pages/item/[name]/index.vue")
  },
  {
    name: "item-name___pt",
    path: "/pt/item/:name()",
    component: () => import("/app/pages/item/[name]/index.vue")
  },
  {
    name: "item-name___ro",
    path: "/ro/item/:name()",
    component: () => import("/app/pages/item/[name]/index.vue")
  },
  {
    name: "item-name___ru",
    path: "/ru/item/:name()",
    component: () => import("/app/pages/item/[name]/index.vue")
  },
  {
    name: "item-name___sv",
    path: "/sv/item/:name()",
    component: () => import("/app/pages/item/[name]/index.vue")
  },
  {
    name: "item-name___tr",
    path: "/tr/item/:name()",
    component: () => import("/app/pages/item/[name]/index.vue")
  },
  {
    name: "item-name___uk",
    path: "/uk/item/:name()",
    component: () => import("/app/pages/item/[name]/index.vue")
  },
  {
    name: "markets-name___ch",
    path: "/ch/markets/:name()",
    component: () => import("/app/pages/markets/[name].vue")
  },
  {
    name: "markets-name___da",
    path: "/da/markets/:name()",
    component: () => import("/app/pages/markets/[name].vue")
  },
  {
    name: "markets-name___de",
    path: "/de/markets/:name()",
    component: () => import("/app/pages/markets/[name].vue")
  },
  {
    name: "markets-name___en",
    path: "/markets/:name()",
    component: () => import("/app/pages/markets/[name].vue")
  },
  {
    name: "markets-name___es",
    path: "/es/markets/:name()",
    component: () => import("/app/pages/markets/[name].vue")
  },
  {
    name: "markets-name___fr",
    path: "/fr/markets/:name()",
    component: () => import("/app/pages/markets/[name].vue")
  },
  {
    name: "markets-name___nl",
    path: "/nl/markets/:name()",
    component: () => import("/app/pages/markets/[name].vue")
  },
  {
    name: "markets-name___pl",
    path: "/pl/markets/:name()",
    component: () => import("/app/pages/markets/[name].vue")
  },
  {
    name: "markets-name___pt",
    path: "/pt/markets/:name()",
    component: () => import("/app/pages/markets/[name].vue")
  },
  {
    name: "markets-name___ro",
    path: "/ro/markets/:name()",
    component: () => import("/app/pages/markets/[name].vue")
  },
  {
    name: "markets-name___ru",
    path: "/ru/markets/:name()",
    component: () => import("/app/pages/markets/[name].vue")
  },
  {
    name: "markets-name___sv",
    path: "/sv/markets/:name()",
    component: () => import("/app/pages/markets/[name].vue")
  },
  {
    name: "markets-name___tr",
    path: "/tr/markets/:name()",
    component: () => import("/app/pages/markets/[name].vue")
  },
  {
    name: "markets-name___uk",
    path: "/uk/markets/:name()",
    component: () => import("/app/pages/markets/[name].vue")
  },
  {
    name: "markets___ch",
    path: "/ch/markets",
    component: () => import("/app/pages/markets/index.vue")
  },
  {
    name: "markets___da",
    path: "/da/markets",
    component: () => import("/app/pages/markets/index.vue")
  },
  {
    name: "markets___de",
    path: "/de/markets",
    component: () => import("/app/pages/markets/index.vue")
  },
  {
    name: "markets___en",
    path: "/markets",
    component: () => import("/app/pages/markets/index.vue")
  },
  {
    name: "markets___es",
    path: "/es/markets",
    component: () => import("/app/pages/markets/index.vue")
  },
  {
    name: "markets___fr",
    path: "/fr/markets",
    component: () => import("/app/pages/markets/index.vue")
  },
  {
    name: "markets___nl",
    path: "/nl/markets",
    component: () => import("/app/pages/markets/index.vue")
  },
  {
    name: "markets___pl",
    path: "/pl/markets",
    component: () => import("/app/pages/markets/index.vue")
  },
  {
    name: "markets___pt",
    path: "/pt/markets",
    component: () => import("/app/pages/markets/index.vue")
  },
  {
    name: "markets___ro",
    path: "/ro/markets",
    component: () => import("/app/pages/markets/index.vue")
  },
  {
    name: "markets___ru",
    path: "/ru/markets",
    component: () => import("/app/pages/markets/index.vue")
  },
  {
    name: "markets___sv",
    path: "/sv/markets",
    component: () => import("/app/pages/markets/index.vue")
  },
  {
    name: "markets___tr",
    path: "/tr/markets",
    component: () => import("/app/pages/markets/index.vue")
  },
  {
    name: "markets___uk",
    path: "/uk/markets",
    component: () => import("/app/pages/markets/index.vue")
  },
  {
    name: "pro-players-id___ch",
    path: "/ch/pro-players/:id()",
    component: () => import("/app/pages/pro-players/[id].vue")
  },
  {
    name: "pro-players-id___da",
    path: "/da/pro-players/:id()",
    component: () => import("/app/pages/pro-players/[id].vue")
  },
  {
    name: "pro-players-id___de",
    path: "/de/pro-players/:id()",
    component: () => import("/app/pages/pro-players/[id].vue")
  },
  {
    name: "pro-players-id___en",
    path: "/pro-players/:id()",
    component: () => import("/app/pages/pro-players/[id].vue")
  },
  {
    name: "pro-players-id___es",
    path: "/es/pro-players/:id()",
    component: () => import("/app/pages/pro-players/[id].vue")
  },
  {
    name: "pro-players-id___fr",
    path: "/fr/pro-players/:id()",
    component: () => import("/app/pages/pro-players/[id].vue")
  },
  {
    name: "pro-players-id___nl",
    path: "/nl/pro-players/:id()",
    component: () => import("/app/pages/pro-players/[id].vue")
  },
  {
    name: "pro-players-id___pl",
    path: "/pl/pro-players/:id()",
    component: () => import("/app/pages/pro-players/[id].vue")
  },
  {
    name: "pro-players-id___pt",
    path: "/pt/pro-players/:id()",
    component: () => import("/app/pages/pro-players/[id].vue")
  },
  {
    name: "pro-players-id___ro",
    path: "/ro/pro-players/:id()",
    component: () => import("/app/pages/pro-players/[id].vue")
  },
  {
    name: "pro-players-id___ru",
    path: "/ru/pro-players/:id()",
    component: () => import("/app/pages/pro-players/[id].vue")
  },
  {
    name: "pro-players-id___sv",
    path: "/sv/pro-players/:id()",
    component: () => import("/app/pages/pro-players/[id].vue")
  },
  {
    name: "pro-players-id___tr",
    path: "/tr/pro-players/:id()",
    component: () => import("/app/pages/pro-players/[id].vue")
  },
  {
    name: "pro-players-id___uk",
    path: "/uk/pro-players/:id()",
    component: () => import("/app/pages/pro-players/[id].vue")
  },
  {
    name: "search___ch",
    path: "/ch/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___da",
    path: "/da/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___de",
    path: "/de/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___es",
    path: "/es/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___fr",
    path: "/fr/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___nl",
    path: "/nl/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___pl",
    path: "/pl/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___pt",
    path: "/pt/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___ro",
    path: "/ro/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___ru",
    path: "/ru/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___sv",
    path: "/sv/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___tr",
    path: "/tr/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___uk",
    path: "/uk/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "teams-id___ch",
    path: "/ch/teams/:id()",
    component: () => import("/app/pages/teams/[id].vue")
  },
  {
    name: "teams-id___da",
    path: "/da/teams/:id()",
    component: () => import("/app/pages/teams/[id].vue")
  },
  {
    name: "teams-id___de",
    path: "/de/teams/:id()",
    component: () => import("/app/pages/teams/[id].vue")
  },
  {
    name: "teams-id___en",
    path: "/teams/:id()",
    component: () => import("/app/pages/teams/[id].vue")
  },
  {
    name: "teams-id___es",
    path: "/es/teams/:id()",
    component: () => import("/app/pages/teams/[id].vue")
  },
  {
    name: "teams-id___fr",
    path: "/fr/teams/:id()",
    component: () => import("/app/pages/teams/[id].vue")
  },
  {
    name: "teams-id___nl",
    path: "/nl/teams/:id()",
    component: () => import("/app/pages/teams/[id].vue")
  },
  {
    name: "teams-id___pl",
    path: "/pl/teams/:id()",
    component: () => import("/app/pages/teams/[id].vue")
  },
  {
    name: "teams-id___pt",
    path: "/pt/teams/:id()",
    component: () => import("/app/pages/teams/[id].vue")
  },
  {
    name: "teams-id___ro",
    path: "/ro/teams/:id()",
    component: () => import("/app/pages/teams/[id].vue")
  },
  {
    name: "teams-id___ru",
    path: "/ru/teams/:id()",
    component: () => import("/app/pages/teams/[id].vue")
  },
  {
    name: "teams-id___sv",
    path: "/sv/teams/:id()",
    component: () => import("/app/pages/teams/[id].vue")
  },
  {
    name: "teams-id___tr",
    path: "/tr/teams/:id()",
    component: () => import("/app/pages/teams/[id].vue")
  },
  {
    name: "teams-id___uk",
    path: "/uk/teams/:id()",
    component: () => import("/app/pages/teams/[id].vue")
  },
  {
    name: "teams___ch",
    path: "/ch/teams",
    component: () => import("/app/pages/teams/index.vue")
  },
  {
    name: "teams___da",
    path: "/da/teams",
    component: () => import("/app/pages/teams/index.vue")
  },
  {
    name: "teams___de",
    path: "/de/teams",
    component: () => import("/app/pages/teams/index.vue")
  },
  {
    name: "teams___en",
    path: "/teams",
    component: () => import("/app/pages/teams/index.vue")
  },
  {
    name: "teams___es",
    path: "/es/teams",
    component: () => import("/app/pages/teams/index.vue")
  },
  {
    name: "teams___fr",
    path: "/fr/teams",
    component: () => import("/app/pages/teams/index.vue")
  },
  {
    name: "teams___nl",
    path: "/nl/teams",
    component: () => import("/app/pages/teams/index.vue")
  },
  {
    name: "teams___pl",
    path: "/pl/teams",
    component: () => import("/app/pages/teams/index.vue")
  },
  {
    name: "teams___pt",
    path: "/pt/teams",
    component: () => import("/app/pages/teams/index.vue")
  },
  {
    name: "teams___ro",
    path: "/ro/teams",
    component: () => import("/app/pages/teams/index.vue")
  },
  {
    name: "teams___ru",
    path: "/ru/teams",
    component: () => import("/app/pages/teams/index.vue")
  },
  {
    name: "teams___sv",
    path: "/sv/teams",
    component: () => import("/app/pages/teams/index.vue")
  },
  {
    name: "teams___tr",
    path: "/tr/teams",
    component: () => import("/app/pages/teams/index.vue")
  },
  {
    name: "teams___uk",
    path: "/uk/teams",
    component: () => import("/app/pages/teams/index.vue")
  },
  {
    name: "tournaments-id___ch",
    path: "/ch/tournaments/:id()",
    meta: _91id_93EKr3F2dlEtMeta || {},
    component: () => import("/app/pages/tournaments/[id].vue"),
    children: [
  {
    name: "tournaments-id-category___ch",
    path: ":category()",
    component: () => import("/app/pages/tournaments/[id]/[category].vue")
  }
]
  },
  {
    name: "tournaments-id___da",
    path: "/da/tournaments/:id()",
    meta: _91id_93EKr3F2dlEtMeta || {},
    component: () => import("/app/pages/tournaments/[id].vue"),
    children: [
  {
    name: "tournaments-id-category___da",
    path: ":category()",
    component: () => import("/app/pages/tournaments/[id]/[category].vue")
  }
]
  },
  {
    name: "tournaments-id___de",
    path: "/de/tournaments/:id()",
    meta: _91id_93EKr3F2dlEtMeta || {},
    component: () => import("/app/pages/tournaments/[id].vue"),
    children: [
  {
    name: "tournaments-id-category___de",
    path: ":category()",
    component: () => import("/app/pages/tournaments/[id]/[category].vue")
  }
]
  },
  {
    name: "tournaments-id___en",
    path: "/tournaments/:id()",
    meta: _91id_93EKr3F2dlEtMeta || {},
    component: () => import("/app/pages/tournaments/[id].vue"),
    children: [
  {
    name: "tournaments-id-category___en",
    path: ":category()",
    component: () => import("/app/pages/tournaments/[id]/[category].vue")
  }
]
  },
  {
    name: "tournaments-id___es",
    path: "/es/tournaments/:id()",
    meta: _91id_93EKr3F2dlEtMeta || {},
    component: () => import("/app/pages/tournaments/[id].vue"),
    children: [
  {
    name: "tournaments-id-category___es",
    path: ":category()",
    component: () => import("/app/pages/tournaments/[id]/[category].vue")
  }
]
  },
  {
    name: "tournaments-id___fr",
    path: "/fr/tournaments/:id()",
    meta: _91id_93EKr3F2dlEtMeta || {},
    component: () => import("/app/pages/tournaments/[id].vue"),
    children: [
  {
    name: "tournaments-id-category___fr",
    path: ":category()",
    component: () => import("/app/pages/tournaments/[id]/[category].vue")
  }
]
  },
  {
    name: "tournaments-id___nl",
    path: "/nl/tournaments/:id()",
    meta: _91id_93EKr3F2dlEtMeta || {},
    component: () => import("/app/pages/tournaments/[id].vue"),
    children: [
  {
    name: "tournaments-id-category___nl",
    path: ":category()",
    component: () => import("/app/pages/tournaments/[id]/[category].vue")
  }
]
  },
  {
    name: "tournaments-id___pl",
    path: "/pl/tournaments/:id()",
    meta: _91id_93EKr3F2dlEtMeta || {},
    component: () => import("/app/pages/tournaments/[id].vue"),
    children: [
  {
    name: "tournaments-id-category___pl",
    path: ":category()",
    component: () => import("/app/pages/tournaments/[id]/[category].vue")
  }
]
  },
  {
    name: "tournaments-id___pt",
    path: "/pt/tournaments/:id()",
    meta: _91id_93EKr3F2dlEtMeta || {},
    component: () => import("/app/pages/tournaments/[id].vue"),
    children: [
  {
    name: "tournaments-id-category___pt",
    path: ":category()",
    component: () => import("/app/pages/tournaments/[id]/[category].vue")
  }
]
  },
  {
    name: "tournaments-id___ro",
    path: "/ro/tournaments/:id()",
    meta: _91id_93EKr3F2dlEtMeta || {},
    component: () => import("/app/pages/tournaments/[id].vue"),
    children: [
  {
    name: "tournaments-id-category___ro",
    path: ":category()",
    component: () => import("/app/pages/tournaments/[id]/[category].vue")
  }
]
  },
  {
    name: "tournaments-id___ru",
    path: "/ru/tournaments/:id()",
    meta: _91id_93EKr3F2dlEtMeta || {},
    component: () => import("/app/pages/tournaments/[id].vue"),
    children: [
  {
    name: "tournaments-id-category___ru",
    path: ":category()",
    component: () => import("/app/pages/tournaments/[id]/[category].vue")
  }
]
  },
  {
    name: "tournaments-id___sv",
    path: "/sv/tournaments/:id()",
    meta: _91id_93EKr3F2dlEtMeta || {},
    component: () => import("/app/pages/tournaments/[id].vue"),
    children: [
  {
    name: "tournaments-id-category___sv",
    path: ":category()",
    component: () => import("/app/pages/tournaments/[id]/[category].vue")
  }
]
  },
  {
    name: "tournaments-id___tr",
    path: "/tr/tournaments/:id()",
    meta: _91id_93EKr3F2dlEtMeta || {},
    component: () => import("/app/pages/tournaments/[id].vue"),
    children: [
  {
    name: "tournaments-id-category___tr",
    path: ":category()",
    component: () => import("/app/pages/tournaments/[id]/[category].vue")
  }
]
  },
  {
    name: "tournaments-id___uk",
    path: "/uk/tournaments/:id()",
    meta: _91id_93EKr3F2dlEtMeta || {},
    component: () => import("/app/pages/tournaments/[id].vue"),
    children: [
  {
    name: "tournaments-id-category___uk",
    path: ":category()",
    component: () => import("/app/pages/tournaments/[id]/[category].vue")
  }
]
  },
  {
    name: "tournaments___ch",
    path: "/ch/tournaments",
    component: () => import("/app/pages/tournaments/index.vue")
  },
  {
    name: "tournaments___da",
    path: "/da/tournaments",
    component: () => import("/app/pages/tournaments/index.vue")
  },
  {
    name: "tournaments___de",
    path: "/de/tournaments",
    component: () => import("/app/pages/tournaments/index.vue")
  },
  {
    name: "tournaments___en",
    path: "/tournaments",
    component: () => import("/app/pages/tournaments/index.vue")
  },
  {
    name: "tournaments___es",
    path: "/es/tournaments",
    component: () => import("/app/pages/tournaments/index.vue")
  },
  {
    name: "tournaments___fr",
    path: "/fr/tournaments",
    component: () => import("/app/pages/tournaments/index.vue")
  },
  {
    name: "tournaments___nl",
    path: "/nl/tournaments",
    component: () => import("/app/pages/tournaments/index.vue")
  },
  {
    name: "tournaments___pl",
    path: "/pl/tournaments",
    component: () => import("/app/pages/tournaments/index.vue")
  },
  {
    name: "tournaments___pt",
    path: "/pt/tournaments",
    component: () => import("/app/pages/tournaments/index.vue")
  },
  {
    name: "tournaments___ro",
    path: "/ro/tournaments",
    component: () => import("/app/pages/tournaments/index.vue")
  },
  {
    name: "tournaments___ru",
    path: "/ru/tournaments",
    component: () => import("/app/pages/tournaments/index.vue")
  },
  {
    name: "tournaments___sv",
    path: "/sv/tournaments",
    component: () => import("/app/pages/tournaments/index.vue")
  },
  {
    name: "tournaments___tr",
    path: "/tr/tournaments",
    component: () => import("/app/pages/tournaments/index.vue")
  },
  {
    name: "tournaments___uk",
    path: "/uk/tournaments",
    component: () => import("/app/pages/tournaments/index.vue")
  },
  {
    name: "trade-up___ch",
    path: "/ch/trade-up",
    meta: trade_45upznQxmV96zNMeta || {},
    component: () => import("/app/pages/trade-up.vue")
  },
  {
    name: "trade-up___da",
    path: "/da/trade-up",
    meta: trade_45upznQxmV96zNMeta || {},
    component: () => import("/app/pages/trade-up.vue")
  },
  {
    name: "trade-up___de",
    path: "/de/trade-up",
    meta: trade_45upznQxmV96zNMeta || {},
    component: () => import("/app/pages/trade-up.vue")
  },
  {
    name: "trade-up___en",
    path: "/trade-up",
    meta: trade_45upznQxmV96zNMeta || {},
    component: () => import("/app/pages/trade-up.vue")
  },
  {
    name: "trade-up___es",
    path: "/es/trade-up",
    meta: trade_45upznQxmV96zNMeta || {},
    component: () => import("/app/pages/trade-up.vue")
  },
  {
    name: "trade-up___fr",
    path: "/fr/trade-up",
    meta: trade_45upznQxmV96zNMeta || {},
    component: () => import("/app/pages/trade-up.vue")
  },
  {
    name: "trade-up___nl",
    path: "/nl/trade-up",
    meta: trade_45upznQxmV96zNMeta || {},
    component: () => import("/app/pages/trade-up.vue")
  },
  {
    name: "trade-up___pl",
    path: "/pl/trade-up",
    meta: trade_45upznQxmV96zNMeta || {},
    component: () => import("/app/pages/trade-up.vue")
  },
  {
    name: "trade-up___pt",
    path: "/pt/trade-up",
    meta: trade_45upznQxmV96zNMeta || {},
    component: () => import("/app/pages/trade-up.vue")
  },
  {
    name: "trade-up___ro",
    path: "/ro/trade-up",
    meta: trade_45upznQxmV96zNMeta || {},
    component: () => import("/app/pages/trade-up.vue")
  },
  {
    name: "trade-up___ru",
    path: "/ru/trade-up",
    meta: trade_45upznQxmV96zNMeta || {},
    component: () => import("/app/pages/trade-up.vue")
  },
  {
    name: "trade-up___sv",
    path: "/sv/trade-up",
    meta: trade_45upznQxmV96zNMeta || {},
    component: () => import("/app/pages/trade-up.vue")
  },
  {
    name: "trade-up___tr",
    path: "/tr/trade-up",
    meta: trade_45upznQxmV96zNMeta || {},
    component: () => import("/app/pages/trade-up.vue")
  },
  {
    name: "trade-up___uk",
    path: "/uk/trade-up",
    meta: trade_45upznQxmV96zNMeta || {},
    component: () => import("/app/pages/trade-up.vue")
  },
  {
    name: "weapons-id___ch",
    path: "/ch/weapons/:id()",
    component: () => import("/app/pages/weapons/[id].vue")
  },
  {
    name: "weapons-id___da",
    path: "/da/weapons/:id()",
    component: () => import("/app/pages/weapons/[id].vue")
  },
  {
    name: "weapons-id___de",
    path: "/de/weapons/:id()",
    component: () => import("/app/pages/weapons/[id].vue")
  },
  {
    name: "weapons-id___en",
    path: "/weapons/:id()",
    component: () => import("/app/pages/weapons/[id].vue")
  },
  {
    name: "weapons-id___es",
    path: "/es/weapons/:id()",
    component: () => import("/app/pages/weapons/[id].vue")
  },
  {
    name: "weapons-id___fr",
    path: "/fr/weapons/:id()",
    component: () => import("/app/pages/weapons/[id].vue")
  },
  {
    name: "weapons-id___nl",
    path: "/nl/weapons/:id()",
    component: () => import("/app/pages/weapons/[id].vue")
  },
  {
    name: "weapons-id___pl",
    path: "/pl/weapons/:id()",
    component: () => import("/app/pages/weapons/[id].vue")
  },
  {
    name: "weapons-id___pt",
    path: "/pt/weapons/:id()",
    component: () => import("/app/pages/weapons/[id].vue")
  },
  {
    name: "weapons-id___ro",
    path: "/ro/weapons/:id()",
    component: () => import("/app/pages/weapons/[id].vue")
  },
  {
    name: "weapons-id___ru",
    path: "/ru/weapons/:id()",
    component: () => import("/app/pages/weapons/[id].vue")
  },
  {
    name: "weapons-id___sv",
    path: "/sv/weapons/:id()",
    component: () => import("/app/pages/weapons/[id].vue")
  },
  {
    name: "weapons-id___tr",
    path: "/tr/weapons/:id()",
    component: () => import("/app/pages/weapons/[id].vue")
  },
  {
    name: "weapons-id___uk",
    path: "/uk/weapons/:id()",
    component: () => import("/app/pages/weapons/[id].vue")
  },
  {
    name: "weapons-all-category___ch",
    path: "/ch/weapons/all/:category()",
    component: () => import("/app/pages/weapons/all/[category].vue")
  },
  {
    name: "weapons-all-category___da",
    path: "/da/weapons/all/:category()",
    component: () => import("/app/pages/weapons/all/[category].vue")
  },
  {
    name: "weapons-all-category___de",
    path: "/de/weapons/all/:category()",
    component: () => import("/app/pages/weapons/all/[category].vue")
  },
  {
    name: "weapons-all-category___en",
    path: "/weapons/all/:category()",
    component: () => import("/app/pages/weapons/all/[category].vue")
  },
  {
    name: "weapons-all-category___es",
    path: "/es/weapons/all/:category()",
    component: () => import("/app/pages/weapons/all/[category].vue")
  },
  {
    name: "weapons-all-category___fr",
    path: "/fr/weapons/all/:category()",
    component: () => import("/app/pages/weapons/all/[category].vue")
  },
  {
    name: "weapons-all-category___nl",
    path: "/nl/weapons/all/:category()",
    component: () => import("/app/pages/weapons/all/[category].vue")
  },
  {
    name: "weapons-all-category___pl",
    path: "/pl/weapons/all/:category()",
    component: () => import("/app/pages/weapons/all/[category].vue")
  },
  {
    name: "weapons-all-category___pt",
    path: "/pt/weapons/all/:category()",
    component: () => import("/app/pages/weapons/all/[category].vue")
  },
  {
    name: "weapons-all-category___ro",
    path: "/ro/weapons/all/:category()",
    component: () => import("/app/pages/weapons/all/[category].vue")
  },
  {
    name: "weapons-all-category___ru",
    path: "/ru/weapons/all/:category()",
    component: () => import("/app/pages/weapons/all/[category].vue")
  },
  {
    name: "weapons-all-category___sv",
    path: "/sv/weapons/all/:category()",
    component: () => import("/app/pages/weapons/all/[category].vue")
  },
  {
    name: "weapons-all-category___tr",
    path: "/tr/weapons/all/:category()",
    component: () => import("/app/pages/weapons/all/[category].vue")
  },
  {
    name: "weapons-all-category___uk",
    path: "/uk/weapons/all/:category()",
    component: () => import("/app/pages/weapons/all/[category].vue")
  }
]
import {useRequest} from "~/composables/request";
import {useGraphQLRequest} from "~/composables/graphql-request";
import type {
    AdvancedSearchInput,
    AdvancedSearchInputV2,
    AdvancedSearchResult,
    BlogCategory,
    BlogPost,
    Category,
    CompareOutput,
    ComparisonFiltersData,
    ComparisonInput,
    ComparisonMarketInfo,
    ComparisonMarketsInput,
    CreateBlogCategoryInput,
    CreateBlogPostInput,
    CreateDynamicPageInput,
    CreateMarketInput,
    DynamicPageInfo,
    GetBlogPostsOutput,
    GetBlogPostsPageParamsInput,
    GetBlogPostsSearchParamsInput,
    ItemColorGroupResult,
    ItemCountByColor,
    ItemPageInfo,
    ItemsByField,
    ItemsContainerPageResult,
    ItemsPriceDiffs,
    ItemStatsByPeriod,
    ItemWeaponPageFiltersResult,
    LatestProfitableContractsResult,
    ProfitableContractInfo,
    MakeVoteResult,
    MarketAdminResponse,
    MarketFullInfo,
    MarketInfo,
    MarketOrderByInput,
    MarketStat,
    MarketStatForPeriod,
    PaginatedProTeams,
    ProPlayersPaginationInput,
    ProPlayerWithItems,
    ProTeam,
    SearchParams,
    SimpleSearch,
    Tournament,
    TournamentInfo,
    TournamentItemsInput,
    TournamentItemsResult,
    UpdateBlogCategoryInput,
    UpdateBlogPostInput,
    UpdateDynamicPageInput,
    UpdateMarketInput,
    UserInfo,
    WeaponPageInput
} from "~/graphql/generated/schema-types";
import {OrderBy, Period, TournamentYearSort} from "~/graphql/generated/schema-types";
import {languagesEnum} from "~/types/LocalesToCockies";

const {request} = useRequest()
const {requestGraph} = useGraphQLRequest()
// TODO: move to env
// const baseUrlV2 = 'https://duke-possession-interest-uniform.trycloudflare.com/api/v2'
const baseUrlV2 = process.env["NUXT_BASE_URL_V2 "] || 'https://api.csmarketcap.com/api/v2'

const locales = Object.keys(languagesEnum).map(i => i.toLowerCase())
const getLang = () => {
    let lang = 'en';
    if (process.client) {
        const href = window.location.pathname
        const locale = href.split('/')[1];
        lang = locales.includes(locale) ? locale : 'en'
    } else {
        const event = useRequestEvent();
        const url = event?.req?.url || event?.path || '';
        const locale = url.split('/')[1];
        lang = locales.includes(locale) ? locale : 'en'
    }

    return languagesEnum[lang.toUpperCase() as keyof typeof languagesEnum]
}

export function getUser() {
    // const session = useNuxtApp()?.ssrContext?.event?.context?.session
    // const headers = session ? {Cookie: `session=${session}`} : {};
    return requestGraph<{
        userInfo: UserInfo
    }>({
        query: `{
          userInfo {
            avatar_url
            balance
            coutry_code
            email
            persona_name
            real_name
            steam_api_key
            steamid64
            trade_link
            is_admin
          }
        }`,
    })
}

export function logout() {
    return request({path: '/auth/logout', baseUrl: baseUrlV2, method: "POST"})
}


export function getCurrencies() {
    return $fetch<{
        data: {
            success: boolean,
            data: {
                type: string,
                value: number
            }[]
        }
    }>('https://pretixe.com/v1/get-currencies', {method: 'GET'})
}

export function getItemsChanges() {
    const lang = getLang()

    return requestGraph<{
        itemsPriceDiffs: ItemsPriceDiffs[]
    }>({
        query: `query getItemsPriceDiffs($lang: String!) {
            itemsPriceDiffs(lang: $lang) {
                market_hash_name
                link
                localed_name
                price_difference
                price
                rarity_color
                is_stattrak
                is_souvenir
            }
    }`,
        variables: {lang}
    })
}

export function getMarketsStats() {
    return requestGraph<{
        marketsMainStats: MarketStat[]
    }>(
        {
            query: `{
                    marketsMainStats {
                        market_name
                        market_full_name
                        market_logo_name
                        today_sales_count
                        today_count_diff
                        today_sales_value
                        today_value_diff
                        seven_days_sales_value
                        seven_days_value_diff
                        days {
                            date
                            total_count
                            total_value
                        }
                    }
                }
            `,
            // cached: true,
            // cacheTTL: 1000 * 60 * 60 * 12
        }
    )
}

export function getMarketsStatsByPeriod(marketName: string, period: Period) {
    return requestGraph<{
        marketStatForPeriod: MarketStatForPeriod
    }>(
        {
            query: `query GetMarketStatsByPeriod($marketName: String!, $period: Period!) {
                        marketStatForPeriod(marketName: $marketName, period: $period) {
                            max_sales
                            min_sales
                            all_sales
                            avg_sales
                            days {
                                date
                                total_count
                                total_value
                            }
                            weeks {
                                week_start_date
                                week_end_date
                                total_count
                                total_value
                            }
                        }
                    }
            `,
            variables: {
                marketName,
                period
            }
        }
    )
}

export function getProviders(
    {
        price_compare
    }: {
        price_compare?: boolean
    }): Promise<{
    success: boolean
    msg?: string
    data: any
}> {
    let path = `/v1/providers`

    if (price_compare && price_compare === true) {
        path += `?price_compare=true`
    }

    return request({path})
}


export function simpleSearch(search: string | null = '', lang: string | null = 'en') {
    return requestGraph<{
        simpleSearch: SimpleSearch[]
    }>({
        query: `query SimpleSearch($searchText: String!, $lang: String) {
                        simpleSearch(searchText: $searchText, lang: $lang) {
                            breadcrumbs {
                                title
                                link
                            }
                            document {
                                title
                                link
                                image
                                background_color
                            }
                      }
              }`,
        variables: {
            searchText: search,
            lang: languagesEnum[lang?.toUpperCase() as keyof typeof languagesEnum] || 'en'
        }
    })
}

export function getCategoryMenu() {
    return requestGraph<{
        getCategories: Category[]
    }>({
        query: `
          {
              getCategories {
                    category
                    items {
                        full_name
                        weapon
                    }
                }
          }
        `,
        // cached: true
    })
}


export function getItemGeneral({name}: {
    name: string
}) {
    const lang = getLang()

    return requestGraph<{
        itemPageInfo: ItemPageInfo
    }>({
        query: `
            query ItemPageInfo($itemLink: String!, $lang: String!) {
                itemPageInfo(itemLink: $itemLink, lang: $lang) {
                    id
                    link
                    market_hash_name
                    category
                    rarity_color
                    min_float
                    max_float
                    team
                    tournament_event
                    skin
                    min_float
                    max_float
                    crates {
                        link
                        market_hash_name
                    }
                    pro_players {
                        id
                        nick_name
                        avatar
                    }
                    collection
                    popularity
                    designer_name
                    designer_link
                    workshop_link
                    update_link
                    update_text
                    weapon
                    exterior
                    sticker_effect
                    phases {
                        market_hash_name
                        link_name
                        phase
                    }
                    is_stattrak
                    is_souvenir
                    current_price
                    last_update
                    phase
                    contains {
                        market_hash_name
                        link
                    }
                    locals {
                        localed_name
                        skin
                        rarity
                        pattern
                        localed_collection
                        description
                    }
                    family_skins {
                        market_hash_name
                        localed_name
                        price
                        rarity_color
                        link
                    }
                    similar_skins {
                        market_hash_name
                        localed_name
                        price
                        rarity_color
                        link
                    }
                    exteriors {
                        name
                        exterior
                        extra_name
                        link
                        extra_link
                    }
                    volume {
                        link
                        full_name
                        min_price
                        last_update
                        market
                        offers
                        extra_full_name
                        extra_price
                        extra_offers
                        extra_market
                        extra_last_update
                        extra_link
                    }
                    markets_info {
                        logo_name
                        market_full_name
                        market_name
                        rating
                        votes
                        trust_pilot_link
                    }
                    markets_offers {
                        market_name
                        price
                        offers
                        last_update
                        price_locked
                    }
                    descriptions {
                        title
                        value
                    }
                    price_statistics {
                        seven_day_average
                        thirty_day_average
                        seven_day_high
                        seven_day_low
                        thirty_day_high
                        thirty_day_low
                        ninety_day_high
                        ninety_day_low
                        all_time_high
                        all_time_low
                        seven_day_chart {
                            date
                            min_price
                        }
                    }
                    colors {
                        hex
                        title
                        percentage
                    }
                    colorItemCount {
                        title
                        count
                    }
                    userVote
                    rating {
                        rating
                        votes
                    }
                    
                    graffityTypes {
                      color
                      link_name
                    }
                    available_markets
                }
            }
        `,
        variables: {itemLink: name, lang}
    })
}


export function getItemSecondaryData(name: string) {
    const lang = getLang()

    return requestGraph<{
        itemPageInfo: ItemPageInfo
    }>({
        query: `
            query ItemPageInfo($itemLink: String!, $lang: String!) {
                itemPageInfo(itemLink: $itemLink, lang: $lang) { 
                    available_markets
                }
            }
        `,
        // chart {
        //     week_start_date
        //     week_end_date
        //     min_price
        //     max_price
        //     avg_price
        //     total_count
        //     total_value
        // }
        // seven_days {
        //     date
        //     min_price
        //     max_price
        //     avg_price
        //     total_count
        //     total_value
        // }
        variables: {itemLink: name, lang}
    })
}

export function getSaleHistory({period, market, item}: {
    period: Period
    market: string
    item: string
}) {
    return requestGraph<{
        itemStatsByPeriod: ItemStatsByPeriod
    }>({
        query: `query ItemStatsByPeriod($period: Period!, $marketName: String!, $itemName: String!) {
                    itemStatsByPeriod(period: $period, marketName: $marketName, itemName: $itemName) {
                        weekly {
                            week_start_date
                            week_end_date
                            min_price
                            max_price
                            avg_price
                            total_count
                            total_value
                        }
                        daily {
                            date
                            min_price
                            max_price
                            avg_price
                            total_count
                            total_value
                        }
                    }
                }
        `,
        variables: {
            period,
            marketName: market,
            itemName: item
        }
    })
}

export function getWeaponItems(
    {category, page, extra, rarity, team}: WeaponPageInput,
    signal: AbortSignal | null = null
) {
    const lang = getLang();

    return requestGraph<{
        itemsWeaponPage: {
            result: ItemsByField[],
            max_pages: number,
        }
        itemsWeaponPageFilters: ItemWeaponPageFiltersResult
    }>({
        query: `query itemsWeaponPage($input: WeaponPageInput!, $category: String!) {
                    itemsWeaponPage(input: $input) {
                        max_pages
                        result {
                            link
                            offers
                            markets_with_offers
                            market_hash_name
                            localed_name
                            weapon
                            category
                            skin
                            rarity_color
                            cases {
                                link
                                market_hash_name
                            }
                            collection
                            has_stattrak
                            has_souvenir
                            phase
                            rating
                            votes
                            is_music_kit_box
                            price_range {
                                market_hash_name_lowest
                                market_hash_name_highest
                                highest_price
                                lowest_price
                                highest_link
                                lowest_link
                            }
                            extra_range {
                                market_hash_name_lowest
                                market_hash_name_highest
                                highest_price
                                lowest_price
                                highest_link
                                lowest_link
                            }
                        }
                    }
                    itemsWeaponPageFilters(category: $category) {
                        normal_count
                        stattrak_count
                        souvenir_count
                        teams
                        rarities_count {
                            name
                            count
                        }
                    }
                }`,
        variables: {
            input: {
                lang,
                category,
                page: page || 1,
                extra,
                rarity,
                team
            },
            category
        },
        signal
    });
}

export function setItemRating(itemName: string, rating: number) {
    return requestGraph<{
        makeVote: MakeVoteResult
    }>({
        query: `mutation MakeVote($input: String!, $vote: Int!) {
            makeVote(input: { marketHashName: $input, vote: $vote }) {
                market_hash_name
                votes
                rating
            }
        }`,
        variables: {input: itemName, vote: rating}
    })
}

export function getItemRating(itemId: number) {
    return requestGraph<{
        getItemRating: {
            rating: number,
            votes: number
        }
    }>({
        query: `query GetItemRating($itemID: Int!) {
            getItemRating(itemID: $itemID) {
                rating
                votes
            }
        }`,
        variables: {itemID: itemId}
    })
}

export function getUserVote(name: string) {
    return requestGraph<{
        getUserVote: number
    }>({
        query: `query GetUserVote($marketHashName: String!) {
                getUserVote(marketHashName: $marketHashName)
            }`,
        variables: {marketHashName: name}
    })
}


export function getAdvancedSearchParams() {
    return requestGraph<{
        searchParams: SearchParams
    }>({
        query: `query SearchParams {
                    searchParams {
                        categories
                        collections
                        exteriors
                        phases
                        rarities
                        sticker_effects
                        teams
                        tournament_events
                        weapons
                        extra
                        order_by
                        color_group
                        group_by
                        colors {
                            title
                            hex
                        }
                    }
                }`,
    })
}

export function advancedSearch(filters: AdvancedSearchInput, rate: number, page: number = 1) {
    const lang = getLang()

    if (!Object.keys(filters).length) {
        filters['orderBy'] = OrderBy.Popularity
    }

    if ('minPrice' in filters && filters['minPrice']) {
        filters['minPrice'] = +((filters['minPrice'] / rate) * 1000).toFixed(0)
    }
    if ('maxPrice' in filters && filters['maxPrice']) {
        filters['maxPrice'] = +((filters['maxPrice'] / rate) * 1000).toFixed(0)
    }

    return requestGraph<{
        advancedSearch: AdvancedSearchResult
    }>({
        query: `query AdvancedSearch($page: Int!, $searchParams: AdvancedSearchInput!, $lang: String) {
                    advancedSearch(page: $page, searchParams: $searchParams, lang: $lang) {
                        max_pages
                        result {
                            link
                            market_hash_name
                            is_stattrak
                            is_souvenir
                            has_stattrak
                            has_souvenir
                            rarity_color
                            exterior
                            skin
                            rarity
                            localed_name
                            localed_skin
                            localed_rarity
                            crates {
                                link
                                market_hash_name
                            }
                            collection
                            offers
                            min_price
                            max_price
                            markets
                            last_update
                            phase
                            min_float
                            max_float
                            item_min_float
                            item_max_float
                        }
                    }
                }`,
        variables: {
            page,
            searchParams: filters,
            lang
        }
    })
}


// export function getComparePageFilters() {
//     return requestGraph<{
//         comparePageFilters: ComparePageFilters
//     }>({
//         query: `query ComparePageFilters {
//                     comparePageFilters {
//                         rarities
//                         exteriors
//                         collections
//                         categories
//                         active_markets {
//                             logo_name
//                             market_full_name
//                             market_name
//                         }
//                     }
//                 }`,
//
//     })
// }

// export function getComparisonItems(filters: Partial<ComparePageInfoInput> | ComparePageInfoInput, rate: number) {
//
//     const lang = getLang()
//
//
//     filters = {...filters}
//
//     if ('minPrice' in filters && filters['minPrice']) {
//         filters['minPrice'] = +((filters['minPrice'] / rate) * 1000).toFixed(0)
//     }
//     if ('maxPrice' in filters && filters['maxPrice']) {
//         filters['maxPrice'] = +((filters['maxPrice'] / rate) * 1000).toFixed(0)
//     }
//
//     filters = cleanObject<Partial<ComparePageInfoInput> | ComparePageInfoInput>(filters)
//
//     return requestGraph<{
//         comparePageInfo: ComparePair[]
//     }>({
//         query: `query ComparePageInfo($input: ComparePageInfoInput!) {
//                         comparePageInfo(input: $input) {
//                             market_hash_name
//                             is_stattrak
//                             is_souvenir
//                             exterior
//                             phase
//                             rarity
//                             rarity_color
//                             weapon
//                             localed_name
//                             localed_rarity
//                             localed_skin
//                             market_from {
//                                 price
//                                 offers
//                                 market_name
//                                 difference
//                                 sales {
//                                     min
//                                     max
//                                     avg
//                                     count
//                                 }
//                             }
//                             market_to {
//                                 price
//                                 offers
//                                 market_name
//                                 difference
//                                 sales {
//                                     min
//                                     max
//                                     avg
//                                     count
//                                 }
//                             }
//                     }
//                 }`,
//         variables: {
//             input: {...filters, lang}
//         }
//     })
// }

export function getItemsContainerPage(
    containerLinkName: string,
    isRareSpecial: boolean = false,
    isStatTrak: boolean = false,
    exteriors: null | string[] = null,
) {

    const lang = getLang()


    return requestGraph<{
        itemsContainerPage: ItemsContainerPageResult
    }>({
        query: `query ItemsContainerPage($containerLinkName: String!, $isRareSpecial: Boolean!, $isStatTrak: Boolean, $exteriors: [String!], $lang: String!) {
                    itemsContainerPage(
                            containerLinkName: $containerLinkName
                            isRareSpecial: $isRareSpecial
                            isStatTrak: $isStatTrak
                            exteriors: $exteriors
                            lang: $lang
                        ) {
                            normal
                            knives
                            gloves
                            localed_name
                            description
                            result {
                                link
                                offers
                                markets_with_offers
                                market_hash_name
                                localed_name
                                weapon
                                category
                                skin
                                rarity_color
                                rarity
                                cases {
                                    market_hash_name
                                    link
                                }
                                collection
                                has_stattrak
                                has_souvenir
                                phase
                                votes
                                rating
                                price_range {
                                    market_hash_name_lowest
                                    market_hash_name_highest
                                    highest_price
                                    lowest_price
                                    lowest_link
                                    highest_link
                                }
                                extra_range {
                                    market_hash_name_lowest
                                    market_hash_name_highest
                                    highest_price
                                    lowest_price
                                    lowest_link
                                    highest_link
                                }
                                min_float
                                max_float
                                item_min_float
                                item_max_float
                            }
                        }
                }`,
        variables: {
            containerLinkName,
            isRareSpecial,
            isStatTrak,
            exteriors,
            lang
        }
    })
}

export function getBlogCategories(useLang: boolean = true) {
    const lang = getLang()

    return requestGraph<{
        getBlogCategories: BlogCategory[]
    }>({
        query: `query GetBlogCategories($lang: String) {
                    getBlogCategories(input: { lang: $lang }) {
                        link
                        title
                        color
                        lang
                        posts_count
                        data {
                            lang
                            title
                        }
                    }
                }
            `,
        variables: {
            lang: useLang ? lang : 'en'
        }
    })
}

export function
getBlogPosts(variables: GetBlogPostsSearchParamsInput & GetBlogPostsPageParamsInput, useLang: boolean = true) {
    return requestGraph<{
        getBlogPosts: GetBlogPostsOutput
    }>({
        query: `query GetBlogPosts($lang: String, $searchText: String, $category: String, $viewed: Boolean, $liked: Boolean, $hidden: Boolean, $is_main: Boolean, $page: Int!, $limit: Int!) {
                    getBlogPosts(
                        searchParams: {
                            lang: $lang
                            searchText: $searchText
                            category: $category
                            viewed: $viewed
                            liked: $liked
                            hidden: $hidden
                            is_main: $is_main
                        }
                        pageParams: { page: $page, limit: $limit }
                    ) {
                        max_pages
                        data {
                            lang    
                            link
                            img
                            title
                            description
                            read_time
                            is_main
                            published_at
                            updated_at
                            views
                            likes_count
                            hidden
                            langs
                            category {
                                title
                                color
                                created_at
                                updated_at
                                link
                                data {
                                    lang
                                    title
                                }
                            }
                            author {
                                persona_name
                                real_name
                                avatar_url
                                steamid64
                            }
                        }
                    }
                }

            `,
        variables: {...variables, lang: useLang ? (variables.lang || 'en') : 'en'}
    })
}

export function getBlogPost(slug: string, lang: string | null = 'en') {
    return requestGraph<{
        getBlogPost: BlogPost
    }>({
        query: `query GetBlogPost($lang: String!, $link: String!) {
                    getBlogPost(
                        input: {
                            lang: $lang
                            link: $link
                        }
                    ) {
                        lang
                        link
                        img
                        hidden
                        title
                        content
                        description
                        tags
                        read_time
                        views
                        likes_count
                        viewed
                        liked
                        published_at
                        is_main
                        langs
                        category {
                            link
                            lang
                            title
                            color
                            data {
                                lang
                                title
                            }
                        }
                        author {
                            persona_name
                            avatar_url
                            steamid64
                        }
                    }
                }

            `,
        variables: {link: slug, lang},
    })
}

export function setBlogViewed(slug: string) {
    return requestGraph<{
        getBlogPost: BlogPost
    }>({
        query: `
            mutation ViewBlogPost($link: String!) {
                viewBlogPost(input: { link: $link })
            }
        `,
        variables: {link: slug}
    })
}

export function setBlogLike(slug: string) {
    return requestGraph<{
        getBlogPost: BlogPost
    }>({
        query: `
            mutation LikeBlogPost($link: String!) {
                likeBlogPost(input: { link: $link })
            }
        `,
        variables: {link: slug}
    })
}

export function removeBlogPost(slug: string, lang: string) {
    return requestGraph<string>({
        query: `
            mutation DeleteBlogPost($link: String!, $lang: String!) {
                deleteBlogPost(input: { link: $link, lang: $lang })
            }

        `,
        variables: {link: slug, lang}
    })
}

export function updateBlogPost(input: UpdateBlogPostInput) {
    return requestGraph<string>({
        query: `mutation UpdateBlogPost($input: UpdateBlogPostInput!) {
    updateBlogPost(
        input: $input
    ) {link}}
        `,
        variables: {input}
    })
}

export function createBlogPost(input: CreateBlogPostInput) {
    return requestGraph<string>({
        query: `
            mutation CreateBlogPost($input: CreateBlogPostInput!) {
                createBlogPost(input: $input) {
                    link
                }
            }

        `,
        variables: {input}
    })
}

export function createBlogCategory(input: CreateBlogCategoryInput) {
    return requestGraph<string>({
        query: `
        mutation CreateBlogCategory($input: CreateBlogCategoryInput!) {
            createBlogCategory(input: $input) {
                link
                lang
                title
                color
                posts_count
                created_at
                updated_at
            }
        }`,
        variables: {input}
    })
}

export function updateBlogCategory(input: UpdateBlogCategoryInput) {
    return requestGraph<string>({
        query: `
        mutation UpdateBlogCategory($input: UpdateBlogCategoryInput!) {
            updateBlogCategory(input: $input) {
                link
                lang
                title
                color
                posts_count
                created_at
                updated_at
            }
        }`,
        variables: {input}
    })
}

export function deleteBlogCategory(slug: string) {
    return requestGraph<string>({
        query: `
            mutation DeleteBlogCategory($link: String!) {
                deleteBlogCategory(input: { link: $link })
            }

        `,
        variables: {link: slug}
    })
}


export async function getAmpBlogPost(slug: string, lang: string | null = 'en') {
    lang = languagesEnum[lang?.toUpperCase() as keyof typeof languagesEnum] || 'en'

    const query = `query GetBlogPost($lang: String!, $link: String!) {
                    getBlogPost(
                        input: {
                            lang: $lang
                            link: $link
                        }
                    ) {
                        lang
                        link
                        img
                        hidden
                        title
                        content
                        description
                        tags
                        read_time
                        views
                        likes_count
                        viewed
                        liked
                        published_at
                        updated_at
                        is_main
                        langs
                        category {
                            link
                            lang
                            title
                            color
                            data {
                                lang
                                title
                            }
                        }
                        author {
                            persona_name
                            avatar_url
                            steamid64
                        }
                    }
                }
    `;

    const response = await fetch(baseUrlV2 + '/graphql', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            query,
            variables: {link: slug, lang}
        }),
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    const result = await response.json();

    if (result.errors) {
        throw new Error(result.errors[0]?.message || 'GraphQL error');
    }

    return {
        data: result.data as {
            getBlogPost: BlogPost
        },
        errors: result.errors
    };
}

export async function getRSSBlogPosts(variables: GetBlogPostsSearchParamsInput & GetBlogPostsPageParamsInput) {
    const lang = languagesEnum[variables.lang?.toUpperCase() as keyof typeof languagesEnum] || 'en'

    const query = `query GetBlogPosts($lang: String, $searchText: String, $category: String, $viewed: Boolean, $liked: Boolean, $hidden: Boolean, $is_main: Boolean, $page: Int!, $limit: Int!) {
                    getBlogPosts(
                        searchParams: {
                            lang: $lang
                            searchText: $searchText
                            category: $category
                            viewed: $viewed
                            liked: $liked
                            hidden: $hidden
                            is_main: $is_main
                        }
                        pageParams: { page: $page, limit: $limit }
                    ) {
                        max_pages
                        data {
                            lang    
                            link
                            img
                            title
                            description
                            read_time
                            is_main
                            published_at
                            updated_at
                            views
                            likes_count
                            hidden
                            langs
                            category {
                                title
                                color
                                created_at
                                updated_at
                                link
                                data {
                                    lang
                                    title
                                }
                            }
                            author {
                                persona_name
                                real_name
                                avatar_url
                                steamid64
                            }
                        }
                    }
                }

            `;

    const response = await fetch(baseUrlV2 + '/graphql', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            query,
            variables: {...variables, lang},
        }),
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    const result = await response.json();

    if (result.errors) {
        throw new Error(result.errors[0]?.message || 'GraphQL error');
    }

    return {
        data: result.data as {
            getBlogPosts: GetBlogPostsOutput
        },
        errors: result.errors
    };
}

export function getComparisonPageItems(filters: ComparisonInput, rate: number, abortSignal: AbortSignal | null = null) {
    filters.lang = languagesEnum[filters.lang?.toUpperCase() as keyof typeof languagesEnum] || 'en'

    const prices: any = {}
    if ('price_from' in filters.filter && filters.filter['price_from']) {
        prices['price_from'] = +((filters.filter['price_from'] / rate) * 1000).toFixed(0)
    }
    if ('price_to' in filters.filter && filters.filter['price_to']) {
        prices['price_to'] = +((filters.filter['price_to'] / rate) * 1000).toFixed(0)
    }
    if ('profit_value_to' in filters.filter && filters.filter['profit_value_to']) {
        prices['profit_value_to'] = +((filters.filter['profit_value_to'] / rate) * 1000).toFixed(0)
    }
    if ('profit_value_from' in filters.filter && filters.filter['profit_value_from']) {
        prices['profit_value_from'] = +((filters.filter['profit_value_from'] / rate) * 1000).toFixed(0)
    }

    return requestGraph<{
        comparePageInfo: CompareOutput
    }>({
        query: `query ComparePageInfo($input: ComparisonInput!) {
                    comparePageInfo(input: $input) {
                        items {
                            market_hash_name
                            link
                            localed_name
                            localed_rarity
                            localed_skin
                            is_stattrak
                            is_souvenir
                            phase
                            rarity
                            rarity_color
                            weapon
                            liked
                            sales
                            exterior {
                                name
                                code
                            }
                            min_price {
                                price
                                market {
                                    logo_name
                                    market_full_name
                                    market_name
                                }
                            }
                            max_price {
                                price
                                market {
                                    logo_name
                                    market_full_name
                                    market_name
                                }
                            }
                            steam_price {
                                price
                                market {
                                    logo_name
                                    market_full_name
                                    market_name
                                }
                            }
                            profit {
                                value
                                percentage
                            }
                        }
                        settings {
                            page
                            lang
                            sort
                            totalCount
                            hasNextPage
                        }
                    }
                }
            `,
        variables: {
            input: {
                ...filters, filter: {
                    ...cleanObject(filters.filter),
                    ...prices,
                    markets_from: filters.filter.markets_from?.length ? filters.filter.markets_from : null,
                    markets_to: filters.filter.markets_to?.length ? filters.filter.markets_to : null,
                }
            },
        },
        signal: abortSignal
    })
}

export function getComparisonMarketsInfo(filters: ComparisonMarketsInput) {
    return requestGraph<{
        compareMarketsInfo: ComparisonMarketInfo
    }>({
        query: `query CompareMarketsInfo($input: ComparisonMarketsInput!) {
                    compareMarketsInfo(
                        input: $input
                    ) {
                        sales
                        min_price
                        max_price
                        average_price
                        offers {
                            price
                            market {
                                logo_name
                                market_full_name
                                market_name
                            }
                        }
                    }
                }
            `,
        variables: {input: filters},
    })
}

export function getComparisonFilters() {
    return requestGraph<{
        comparePageFilters: ComparisonFiltersData
    }>({
        query: `query ComparePageFilters {
                    comparePageFilters {
                        collections
                        exteriors
                        markets {
                            logo_name
                            market_full_name
                            market_name
                        }
                        types {
                            category
                            items {
                                full_name
                                weapon
                            }
                        }
                        rarityGroups {
                            title
                            rarities {
                                rarity
                                rarity_color
                            }
                        }
                        categories {
                            title
                            id
                        }
                    }
                }
            `,
    })
}

export function likeItem(name: string) {
    return requestGraph<{
        likeItem: string
    }>({
        query: `mutation LikeItem($itemLink: String!) {
                    likeItem(itemLink: $itemLink)
                }
            `,
        variables: {itemLink: name},

    })
}

export function getColorGroups() {
    return requestGraph<{
        itemColorGroup: ItemColorGroupResult[]
    }>({
        query: `query ItemColorGroup {
                    itemColorGroup {
                        group
                        containedColors {
                            hex
                            title
                        }
                    }
                }

            `,
    })
}

export function deleteFile(url: string) {
    return requestGraph<{
        likeItem: string
    }>({
        query: `mutation DeleteFile($url: String!) {
                    deleteFile(url: $url)
                }
            `,
        variables: {url},

    })

}

export function getDynamicPage(slug: string, lang: string, page: number) {
    return requestGraph<{
        dynamicPage: DynamicPageInfo
    }>({
        query: `query DynamicPage($slug: String!, $lang: String!, $page: Int!) {
                    dynamicPage {
                        info(slug: $slug) {
                            slug
                            image_url
                            locals {
                                local
                                user_text
                                user_description
                                seo_tags {
                                    title
                                    description
                                }
                            }
                            advancedSearchParameters {
                                minPrice
                                maxPrice
                                exterior
                                collection
                                tournamentEvent
                                weapon
                                category
                                phase
                                rarity
                                team
                                stickerEffect
                                extra
                                searchText
                                orderBy
                                groupBy
                                colorGroup
                                color
                            }
                        }
                        data(slug: $slug, lang: $lang, page: $page) {
                            max_pages
                            result {
                                market_hash_name
                                link
                                is_stattrak
                                is_souvenir
                                has_stattrak
                                has_souvenir
                                rarity_color
                                exterior
                                skin
                                rarity
                                localed_name
                                localed_skin
                                localed_rarity
                                collection
                                offers
                                min_price
                                max_price
                                markets
                                last_update
                                phase
                                crates {
                                    market_hash_name
                                    link
                                }
                                colors {
                                    hex
                                    title
                                    percentage
                                }
                            }
                        }
                    }
                }
            `,
        variables: {slug, lang, page},
    })
}

export function getColorsAndColors() {
    return requestGraph<{
        itemsCountByColors: ItemCountByColor[]
    }>({
        query: `query ItemsCountByColors {
                    itemsCountByColors {
                        title
                        hex
                        count
                    }
                }
       `,
    })
}

export function getAllDynamicPages() {
    return requestGraph<{
        dynamicPage: DynamicPageInfo
    }>({
        query: `query DynamicPage {
                    dynamicPage {
                        all {
                            slug
                            title
                        }
                    }
                }
       `,
    })
}

export function removeDynamicPage(slug: string) {
    return requestGraph<{
        deleteDynamicPage: boolean
    }>({
        query: `mutation DeleteDynamicPage($slug: String!) {
                deleteDynamicPage(slug: $slug)
            }
       `,
        variables: {slug}
    })
}

export function createDynamicPage(input: CreateDynamicPageInput, rate: number) {
    if ('minPrice' in input.advanced_search_parameters && input.advanced_search_parameters['minPrice']) {
        input.advanced_search_parameters['minPrice'] = +((input.advanced_search_parameters['minPrice'] / rate) * 1000).toFixed(0)
    }
    if ('maxPrice' in input.advanced_search_parameters && input.advanced_search_parameters['maxPrice']) {
        input.advanced_search_parameters['maxPrice'] = +((input.advanced_search_parameters['maxPrice'] / rate) * 1000).toFixed(0)
    }

    return requestGraph<{
        createDynamicPage: boolean
    }>({
        query: `mutation CreateDynamicPage($input: CreateDynamicPageInput!) {
                createDynamicPage(input: $input)
            }
       `,
        variables: {input}
    })
}

export function updateDynamicPage(input: UpdateDynamicPageInput, rate: number) {
    if ('minPrice' in (input.advanced_search_parameters || {}) && input.advanced_search_parameters?.['minPrice']) {
        input.advanced_search_parameters['minPrice'] = +((input.advanced_search_parameters['minPrice'] / rate) * 1000).toFixed(0)
    }
    if ('maxPrice' in (input.advanced_search_parameters || {}) && input.advanced_search_parameters?.['maxPrice']) {
        input.advanced_search_parameters['maxPrice'] = +((input.advanced_search_parameters['maxPrice'] / rate) * 1000).toFixed(0)
    }

    return requestGraph<{
        updateDynamicPage: boolean
    }>({
        query: `mutation UpdateDynamicPage($input: UpdateDynamicPageInput!) {
                updateDynamicPage(input: $input)
            }
       `,
        variables: {input}
    })
}


export function getProPlayer(nick: string, lang: string, pagination: ProPlayersPaginationInput) {
    return requestGraph<{
        getProPlayer: ProPlayerWithItems
    }>({
        query: `query GetProPlayer($nick_name: String!, $lang: String!, $pagination: ProPlayersPaginationInput!) {
                    getProPlayer(nick_name: $nick_name, pagination: $pagination, lang: $lang) {
                        id
                        config_file
                        general {
                            nick_name
                            avatar
                            birthday
                            country_logo
                            profile_url
                            country
                            description
                            real_name
                            team
                            team_logo
                        }
                        social {
                            twitter
                            twitch
                            instagram
                            steam
                            youtube
                        }
                        mouse {
                            mouse_model
                            mouse_dpi
                            mouse_sensitivity
                            mouse_edpi
                            mouse_zoom_sensitivity
                            mouse_hz
                            mouse_windows_sensitivity
                        }
                        crosshair {
                            crosshair_style
                            crosshair_follow_recoil
                            crosshair_dot
                            crosshair_length
                            crosshair_thickness
                            crosshair_gap
                            crosshair_outline
                            crosshair_outline_thickness
                            crosshair_color
                            crosshair_red
                            crosshair_green
                            crosshair_blue
                            crosshair_alpha
                            crosshair_alpha_value
                            crosshair_t_style
                            crosshair_deployed_weapon_gap
                            crosshair_split_distance
                            crosshair_fixed_gap
                            crosshair_inner_split_alpha
                            crosshair_outer_split_alpha
                            crosshair_split_size_ratio
                            crosshair_sniper_width
                            crosshair_image
                            crosshair_in_game_config
                        }
                        viewmodel {
                            viewmodel_fov
                            viewmodel_offset_x
                            viewmodel_offset_y
                            viewmodel_offset_z
                            viewmodel_preset_pos
                            viewmodel_in_game_config
                        }
                        items {
                            max_pages
                            total_count
                            result {
                                market_hash_name
                                link
                                is_stattrak
                                is_souvenir
                                has_stattrak
                                has_souvenir
                                rarity_color
                                exterior
                                skin
                                rarity
                                localed_name
                                localed_skin
                                localed_rarity
                                collection
                                offers
                                min_price
                                max_price
                                markets
                                last_update
                                phase
                            }
                        }
                        gears {
                            id
                            title
                            image
                            type
                            section
                            link
                        }
                    }
                }

       `,
        variables: {
            nick_name: nick,
            pagination,
            lang: languagesEnum[lang?.toUpperCase() as keyof typeof languagesEnum] || 'en'
        },
    })
}

export function getTournaments(sort: TournamentYearSort) {
    return requestGraph<{
        tournaments: Tournament[]
    }>({
        query: `query Tournaments($sort: TournamentYearSort!) {
                tournaments(sort: $sort) {
                    name
                    year
                    city
                    link_name
                }
            }
       `,
        variables: {sort}
    })
}

export function getTournament(
    page: number,
    searchParams: TournamentItemsInput,
    lang: string,
    limit: number = 10) {
    return requestGraph<{
        tournamentItems: TournamentItemsResult
        tournamentInfo: TournamentInfo
        tournamentItemCategories: string[]
        searchParams: {
            order_by: OrderBy[]
        }
    }>({
        query: `query TournamentItems($page: Int!, $searchParams: TournamentItemsInput!, $lang: String, $eventName: String!, $limit: Int!) {
                    tournamentItemCategories(link_name: $eventName)
                    
                    tournamentInfo(link_name: $eventName) {
                        name
                        city
                        year
                    }
                    
                    tournamentItems(
                        page: $page
                        searchParams: $searchParams
                        lang: $lang
                        limit: $limit
                    ) {
                        max_pages
                        items_length
                        result {
                            market_hash_name
                            link
                            is_stattrak
                            is_souvenir
                            has_stattrak
                            has_souvenir
                            rarity_color
                            exterior
                            skin
                            rarity
                            localed_name
                            localed_skin
                            localed_rarity
                            collection
                            offers
                            min_price
                            max_price
                            markets
                            last_update
                            phase
                            crates {
                                market_hash_name
                                link
                            }
                            colors {
                                hex
                                title
                                percentage
                            }
                        }
                    }
                }

       `,
        variables: {
            page,
            searchParams,
            lang: languagesEnum[lang?.toUpperCase() as keyof typeof languagesEnum] || 'en',
            eventName: searchParams?.event_link || '',
            limit
        }
    })
}

export function getTeams(pagination: ProPlayersPaginationInput, lang: string, search: string) {
    return requestGraph<{
        getProTeams: PaginatedProTeams
    }>({
        query: `query GetProTeams($pagination: ProPlayersPaginationInput!, $lang: String, $search: String) {
                    getProTeams(pagination: $pagination, lang: $lang, search: $search) {
                        max_pages
                        total_count
                        result {
                            title
                            logo
                            link
                            id
                        }
                    }
                }
       `,
        variables: {
            pagination,
            lang: languagesEnum[lang?.toUpperCase() as keyof typeof languagesEnum] || 'en',
            search: search || null
        }
    })
}


export function getTeam(link: string, lang: string) {
    return requestGraph<{
        getProTeam: ProTeam
    }>({
        query: `query GetProTeam($link: String!, $lang: String) {
                 getProTeam(link: $link, lang: $lang) {
                        id
                        title
                        logo
                        description
                        link
                        players {
                            id
                            general {
                                nick_name
                                avatar
                                birthday
                                country_logo
                                profile_url
                                country
                                description
                                real_name
                                team
                                team_logo
                            }
                            social {
                                twitter
                                twitch
                                instagram
                                steam
                                youtube
                            }
                        }
                        gears {
                            id
                            title
                            image
                            type
                            section
                            link
                        }
                        social {
                            twitter
                            twitch
                            instagram
                            steam
                            youtube
                        }
                    }
                }
       `,
        variables: {
            link,
            lang: languagesEnum[lang?.toUpperCase() as keyof typeof languagesEnum] || 'en',
        }
    })
}

export function getTradeUpSearchParams() {
    return requestGraph<{
        searchParams: SearchParams
    }>({
        query: `query SearchParams {
                    searchParams {
                        collections
                        exteriors
                        rarities
                        tournament_events
                        order_by
                    }
                }`,
    })
}

export function getMarkets(order: MarketOrderByInput | null, search?: string) {
    return requestGraph<{
        availableMarkets: MarketInfo[]
    }>({
        query: `query AvailableMarkets($order: MarketOrderByInput, $search: String) {
                    availableMarkets(order: $order, search: $search) {
                        market_name
                        slug
                        market_full_name
                        logo_name
                        rating
                        votes
                        items
                        offers
                        price_sum
                        avg_discount
                        monthly_visit
                        market_type
                    }
                }`,
        variables: {order, search: search || null}
    })
}

export function getFullMarketInfo(slug: string, period: Period, lang: string) {
    return requestGraph<{
        market: MarketFullInfo
    }>({
        query: `query Market($slug: String!, $period: Period!, $lang: String) {
                    market(slug: $slug, lang: $lang) {
                        chart(period: $period) {
                            items
                            offers
                            price_sum
                            avg_discount
                            monthly_visit
                            date
                        }
                        id
                        foundation_date
                        market_name
                        slug
                        market_full_name
                        is_active
                        trust_pilot_link
                        logo_name
                        market_type
                        seller_fee
                        registered_company
                        headquarters
                        key_people
                        monthly_visit
                        rating
                        votes
                        website_url
                        summary
                        items_percentage
                        top_countries {
                            flag
                            country
                            percentage
                        }
                        descriptions {
                            name
                            value
                            icon_name
                        }
                        question_answers {
                            name
                            value
                        }
                        social_media {
                            instagram
                            telegram
                            twitter
                            vk
                            twitch
                        }
                        apps {
                            link
                            logo
                            name
                            type
                        }
                        supply_statistic {
                            items
                            offers
                            price_sum
                            avg_discount
                        }
                        payment_methods {
                            logo
                            name
                        }
                        payout_methods {
                            logo
                            name
                        }
                        supported_games {
                            logo
                            name
                        }
                        alternatives {
                            market_name
                            market_full_name
                            market_type
                            logo_name
                            rating
                            votes
                            items
                            offers
                            price_sum
                            avg_discount
                            monthly_visit
                        }
                    }
                }
`,
        variables: {
            slug,
            period,
            lang: languagesEnum[lang?.toUpperCase() as keyof typeof languagesEnum] || 'en'
        }
    })
}

export function getMarketChart(slug: string, period: Period) {
    return requestGraph<{
        market: MarketFullInfo
    }>({
        query: `query Market($slug: String!, $period: Period!) {
                    market(slug: $slug) {
                        chart(period: $period) {
                            price_sum
                            date
                        }
                    }
                }
            `,
        variables: {
            slug,
            period,
        }
    })
}

export function createMarket(input: CreateMarketInput) {
    return requestGraph<{
        createMarket: boolean
    }>({
        query: `mutation CreateMarket($input: CreateMarketInput!) {
                    createMarket(input: $input)
                }
            `,
        variables: {input}
    })
}

export function updateMarket(name: string, input: UpdateMarketInput) {
    return requestGraph<{
        updateMarket: boolean
    }>({
        query: `mutation UpdateMarket($input: UpdateMarketInput!, $market_name: String!) {
                    updateMarket(market_name: $market_name, input: $input)
                }
            `,
        variables: {input, market_name: name}
    })
}

export function deleteMarket(name: string) {
    return requestGraph<{
        deleteMarket: boolean
    }>({
        query: `mutation DeleteMarket($market_name: String!) {
                    deleteMarket(market_name: $market_name,)
                }
            `,
        variables: {market_name: name}
    })
}


export function getAdminMarketInfo(slug: string) {
    return requestGraph<{
        marketForAdmin: MarketAdminResponse
    }>({
        query: `query MarketForAdmin($slug: String!) {
                    marketForAdmin(slug: $market_name) {
                        logo_name
                        trust_pilot_link
                        is_active
                        market_name
                        slug
                        market_full_name
                        rating
                        votes
                        website_url
                        foundation_date
                        market_type
                        seller_fee
                        registered_company
                        locals {
                            local
                            summary
                            key_people
                            headquarters
                            question_answers {
                                name
                                value
                            }
                            descriptions {
                                name
                                value
                                icon_name
                            }
                            top_countries {
                                flag
                                country
                                code
                            }
                        }
                        social_media {
                            vk
                            twitch
                            twitter
                            telegram
                            instagram
                        }
                        apps {
                            link
                            logo
                            name
                            type
                        }
                        payment_methods {
                            logo
                            name
                        }
                        payout_methods {
                            logo
                            name
                        }
                   }
               }
`,
        variables: {
            slug,
        }
    })
}

export function getTradeByLocation() {
    return requestGraph<{
        tradeByLocation: any[]
    }>({
        query: `query TradeByLocation {
                    tradeByLocation {
                        from_country
                        to_country
                        count
                    }
                }       
            `,
    })
}

export function getTradeUpFilters() {
    return requestGraph<{
        searchParams: SearchParams
    }>({
        query: `query SearchParams {
                    searchParams {
                        rarities
                        group_by
                        order_by
                        collections
                    }
                }       
            `,
    })
}

export function getTradeUpItems(params: AdvancedSearchInputV2, page: number, lang?: string) {
    return requestGraph<{
        advancedSearchV2: AdvancedSearchResult
    }>({
        query: `query AdvancedSearchV2($page: Int!, $searchParams: AdvancedSearchInputV2!, $lang: String) {
                    advancedSearchV2(page: $page, searchParams: $searchParams, lang: $lang) {
                        max_pages
                        max_pages
                        result {
                            market_hash_name
                            link
                            is_stattrak
                            rarity_color
                            exterior
                            skin
                            rarity
                            localed_name
                            localed_skin
                            localed_rarity
                            collection
                            min_price
                            max_price
                            min_float
                            max_float
                            item_min_float
                            item_max_float
                        }
                    }
                }
     
            `,
        variables: {
            page,
            searchParams: params,
            lang: languagesEnum[lang?.toUpperCase() as keyof typeof languagesEnum] || 'en'
        }
    })
}
export function getTradeUpLatestContracts() {
    return requestGraph<{
        latestProfitableContracts: LatestProfitableContractsResult[]
    }>({
        query: `query LatestProfitableContracts {
                    latestProfitableContracts {
                        id
                        cost
                        profit
                        resultItem
                        resultItemLink
                    }
                    }
                `,
    })
}
export function getTradeUpLatestContractItem(id: number) {
    return requestGraph<{
        profitableTradeUpContract: ProfitableContractInfo
    }>({
        query: `query ProfitableTradeUpContract($id: Int!) {
                    profitableTradeUpContract(id: $id) {
                        market_hash_name
                        cost
                        profit
                        items {
                        float
                        market_hash_name
                        link
                        rarity_color
                        item_min_float
                        item_max_float
                        exterior
                        locals {
                            localed_name
                            skin
                        }
                        collection
                        volume {
                            full_name
                            link
                            min_price
                            market
                        }
                        markets_info {
                            market_name
                            market_full_name
                            logo_name
                        }
                        }
                    }
                    }
                `,
        variables: {
            id,
        }
    })
}


export function getTradeUpItemInfo(name: string, lang: string) {
    return requestGraph<{
        itemPageInfo: ItemPageInfo
    }>({
        query: `query ItemPageInfo($itemLink: String!, $lang: String) {
                    itemPageInfo(itemLink: $itemLink, lang: $lang) {
                        market_hash_name
                        link
                        rarity_color
                        item_min_float
                        item_max_float
                        exterior
                        collection
                        volume {
                            full_name
                            link
                            min_price
                            market
                        }
                        markets_info {
                            market_name
                            market_full_name
                            logo_name
                        }
                        locals {
                            localed_name
                            skin
                        }
                    }
                }
     
            `,
        variables: {
            itemLink: name,
            lang: languagesEnum[lang?.toUpperCase() as keyof typeof languagesEnum] || 'en'
        }
    })
}




